// Third party functions
import React, { useEffect, useRef, Suspense, lazy } from "react";
import { useSelector, useDispatch } from "react-redux";

// importing Slice functions for handling state
import { update, getSizes } from "../slicers/layout/layoutSlice.js";
import { getBuildingState } from "../slicers/3D-state/buildingSlice.js";

// ProDes App components and features
import AppBarHeader from "./header.js";
import ProjectGeneralData from "../content/side-bars/ProjectGeneralData.js";
import SelectedElement from "../content/side-bars/SelectedElement.js";

// ProDes App styles
import "./css/layout.css";

// Lazy load the Building component
const Building = lazy(() => import("../building/Building.js"));

// App's main component with the global layout
const AppProject = () => {
  // Hook function for connecting the store
  const dispatch = useDispatch();
  // Getting the actual state from the slicers
  const sizes = useSelector(getSizes);
  const modelState = useSelector(getBuildingState);

  // Connecting with references the header and footer references
  const headerRef = useRef(null);
  const footerRef = useRef(null);
  const sliderSideRef = useRef(null);

  // Event listeners functions references
  const updateWindowSizesRef = useRef(null);
  const setSidebarWidthRef = useRef(null);
  const stopResizingRef = useRef(null);

  // Sidebar panel variables
  const sliderSize = 2;
  let isSidebarResizing = false;

  // Method that will update the new screen sizes
  const updateWindowSizes = () => {
    // Available height for content
    const contentHeight =
      window.innerHeight -
      headerRef.current.offsetHeight -
      footerRef.current.offsetHeight;

    // Defining a new sizes object
    const newSizes = {
      headerHeight: headerRef.current.offsetHeight,
      footerHeight: footerRef.current.offsetHeight,
      windowHeight: window.innerHeight,
      contentHeight: contentHeight,
      contentWidth: window.innerWidth * 0.8 - sliderSize,
      sidebarWidth: window.innerWidth * 0.2,
    };
    // updating state with the new sizes object
    dispatch(update(newSizes));
  };

  // Method for defining event listeners
  const eventListeners = () => {
    // Function that will update the resizing flag that indicated
    // that is going to be a change in the sidebar width
    const setResizingFlag = (event) => {
      // If there is not mounted the slider don't do anything
      if (!sliderSideRef) return;

      // If click has ocurred inside the sidebar slider container
      if (sliderSideRef.current.contains(event.target)) {
        isSidebarResizing = true;
      }

      // Stop further propagation of the event
      event.stopImmediatePropagation();
    };

    // Function that set the sidebar width
    const setSidebarWidth = (event) => {
      // If there is not mounted the slider don't do anything
      if (!sliderSideRef || !isSidebarResizing) return;

      // Setting new widths
      if (
        window.innerWidth * 0.15 <= event.clientX &&
        window.innerWidth * 0.4 >= event.clientX
      ) {
        // Defining a new sizes object
        const newSizes = {
          contentWidth: window.innerWidth - event.clientX,
          sidebarWidth: event.clientX,
        };
        // updating state with the new sizes object
        dispatch(update(newSizes));
      }

      // Stop further propagation of the event
      event.stopImmediatePropagation();
    };

    // Function that stops sidebar width resizing
    const stopResizing = () => {
      isSidebarResizing = false;
    };

    // Handling event listeners
    const handleEventListeners = () => {
      // Connecting references
      updateWindowSizesRef.current = updateWindowSizes;
      setSidebarWidthRef.current = setSidebarWidth;
      stopResizingRef.current = stopResizing;

      // Adding event listeners
      window.addEventListener("resize", updateWindowSizesRef.current);
      sliderSideRef.current.addEventListener("mousedown", setResizingFlag);
      window.addEventListener("mousemove", setSidebarWidthRef.current);
      window.addEventListener("mouseup", stopResizingRef.current);
    };

    // Adding references and event listeners
    handleEventListeners();
  };

  // Sidebar component rendering options
  const sidebarContent = () => {
    // Getting state from building object
    const selectedConnecti = modelState.selectedConnecti;

    // if there is a selected connectivity and it is vertical
    if (selectedConnecti.length) {
      return <SelectedElement />;

      // If there is not a selected connectivity
    } else {
      return <ProjectGeneralData />;
    }
  };

  // On mount
  useEffect(() => {
    // On mount
    updateWindowSizes();
    // Adding event listeners
    eventListeners();

    // Cleanup code to run before unmounting the component
    return () => {
      // Removing event listeners
      window.removeEventListener("resize", updateWindowSizesRef.current);
      window.removeEventListener("mousemove", setSidebarWidthRef.current);
      window.removeEventListener("mouseup", stopResizingRef.current);
    };
  }, []);

  // Rendering the component
  return (
    <div className="project-insight">
      {/* main header container */}
      <div id="header-container" ref={headerRef}>
        <AppBarHeader />
      </div>

      {/* main-body container */}
      <div
        id="body-container"
        style={{
          minHeight: sizes.contentHeight,
        }}
      >
        {/* Sidebar container project dashboard information */}
        <div
          id="sidebar-container"
          style={{
            width: sizes.sidebarWidth,
            height: sizes.contentHeight,
            color: "white",
            fontSize: `${sizes.sidebarWidth * 0.15}px`,
          }}
        >
          {
            // Rendering the slider content
            // depends on the selected connectivity and
            // if the connectivity is from a vertical or horizontal element
            sidebarContent()
          }
        </div>

        {/* Sidebar Slider por handling resizing the containers */}
        <div
          id="sidebar-slider"
          className="slider"
          ref={sliderSideRef}
          style={{ width: sliderSize }}
        ></div>

        {/* Content container project dashboard: list of user's projects */}
        <div
          id="content-container"
          style={{
            width: sizes.contentWidth,
            height: sizes.contentHeight,
          }}
        >
          <Suspense
            fallback={
              <div className="loading-component">Rendering Building...</div>
            }
          >
            <Building />
          </Suspense>
        </div>
      </div>

      {/* main footer container */}
      <div id="footer-container" ref={footerRef}></div>
    </div>
  );
};

export default AppProject;
