// importing third party functions
import { createSlice } from "@reduxjs/toolkit";

export const buildingState = createSlice({
  // Slicer for handling user location path
  name: "buildingState",
  // By default the object start with all locations turned off
  initialState: {
    // Previous state for handling the building
    prevState: null,
    // Option to activate the selection of multiple elements
    multiElemSelection: true,
    // Option to activate the detection of identical elements in different stories
    detectIdentElemOnStories: true,
    // for clicking handling
    clicked: false,
    // for scale in Z axis
    scaleZaxis: 1,
    // for storing the selected connectivities
    selectedConnecti: [],
    // for storing selected element real name
    selectedElementRealName: undefined,
    // for storing selected connectivities group
    selectedConGroup: [],
    // for storing selected vertical connectivities
    selectedVertical: false,
    // for storing selected connectivities type
    selectedType: null,
    // for storing the hovered connectivities
    hoveredConnecti: [],
    // for storing the hovered connectivities group
    hoveredConGroup: [],
    // for storing the hovered connectivities vertical connectivities
    hoveredVertical: false,
    // for handling building's levels visibility
    visibleFloors: [],
    // for handling building's axes visibility
    visibleAxes: [],
    // for handling building's axes visibility
    visibleElements: [],
    // for stopping and restoring 3D model rendering
    isRenderingActive: true
  },
  // Reducer functions for controlling the state
  reducers: {
    // Update user location
    setBuildingState: (state, action) => {
      // Getting keys to update
      const keysToUpdate = Object.keys(action.payload);

      // Storing the previous stated
      state.prevState = { ...state };
      // Deleting previous inner state
      delete state.prevState.prevState;

      // for each key to update it is replaced the values of the state object
      keysToUpdate.forEach((key) => {
        state[key] = action.payload[key];
      });
    },
  },
});

// Exporting the actions that will control the state
export const { setBuildingState } = buildingState.actions;
// Exporting function to get state
export const getBuildingState = (state) => state.buildingState;
// Exporting reducer
export default buildingState.reducer;
