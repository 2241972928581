import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    mode: "dark",
  },
  typography: {
    fontFamily: [
      "Trebuchet MS",
      "Lucida Sans Unicode",
      "Lucida Grande",
      "Lucida Sans",
      "Arial",
      "sans-serif",
    ].join(","),
    fontSize: 12,
  },
});
