// Root imports
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// importing Slice functions for handling state
import {
  setBuildingState,
  getBuildingState,
} from "../../../slicers/3D-state/buildingSlice";

import {
  getGeometry,
  updateStoryName,
} from "../../../slicers/project-geometry/geometrySlice";

// Third party functions and components
import LightModeIcon from "@mui/icons-material/LightMode";
import LayersIcon from "@mui/icons-material/Layers";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Tooltip from "@mui/material/Tooltip";

// ProDes styles import
import "../../css/content.css";

const SingleStory = ({ storyIndex }) => {
  // Hook function for connecting the store
  const dispatch = useDispatch();
  // Getting visible floors state
  const visibleFloors = useSelector(getBuildingState).visibleFloors;
  // Getting storeys info
  const storeys = useSelector(getGeometry).stories;
  // Story name
  const name = storeys[storyIndex].name;

  // State to control the value of the text input
  const [inputValue, setInputValue] = useState(name);
  // Setting local state to handle changes on visibility
  const [visiFloors, setVisiFloors] = useState(visibleFloors);

  // Function to handle input change and format the value
  const handleInputChange = (event) => {
    // user's value
    const inputVal = event.target.value;
    // Setting new state
    setInputValue(inputVal);
  };

  // Method for handling a story name change
  const submitStoryName = () => {
    // Updating names based on user input
    dispatch(updateStoryName({ index: storyIndex, name: inputValue }));
  };

  // Method for turning on group of geometrically indetical levels
  const handleGroupOn = () => {
    // Getting group
    const groupValue = storeys[storyIndex].group;

    // if there is not a group, dont do anything
    if (!groupValue) return;

    // If there is a group value, it will get the story IDs from
    // those that had the same group ID
    const filteredIds = storeys
      .filter((story) => story.group === groupValue)
      .map((story) => parseInt(story.id));

    // Highlights similar storeys
    dispatch(setBuildingState({ visibleFloors: filteredIds }));
  };

  // Method for turning on the level and turning off the rest
  const handleStoryOn = () => {
    // Turn on only the current level
    dispatch(setBuildingState({ visibleFloors: [storyIndex] }));
  };

  // Method for handling invidual level visualization options
  const turnOnOffSingleStory = (action) => {
    // Updating building state with visible story if it is not visible yet
    if (action === "On" && !visiFloors.includes(storyIndex)) {
      dispatch(
        setBuildingState({ visibleFloors: [...visiFloors, storyIndex] })
      );
      // Updating building state without story if it was visible
    } else if (action === "Off" && visiFloors.includes(storyIndex)) {
      dispatch(
        setBuildingState({
          visibleFloors: visiFloors.filter((index) => index !== storyIndex),
        })
      );
    }
  };

  // On changes of story visibility
  useEffect(() => {
    // Updating local state
    setVisiFloors(visibleFloors);
  }, [visibleFloors]);

  return (
    <div className="general-data-item">
      {/* container for story name */}
      <input
        className="data-text-3icons"
        placeholder="Story name"
        size="small"
        value={inputValue}
        // While the value is changing
        onChange={handleInputChange}
        // When the input loses focus, execute submit
        onBlur={submitStoryName}
        // When the user press enter, execute submit
        onKeyDown={(event) => (event.keyCode === 13 ? submitStoryName() : null)}
      ></input>

      {/* Highlights levels matching the group of the current level */}
      <div className="one-of-three-icons">
        <Tooltip title="Highlight similar storeys">
          <LayersIcon color="action" fontSize="small" onClick={handleGroupOn} />
        </Tooltip>
      </div>

      {/* Highlights only the current level */}
      <div className="one-of-three-icons">
        <Tooltip title="Highlight current level">
          <LightModeIcon
            color="action"
            fontSize="small"
            onClick={handleStoryOn}
          />
        </Tooltip>
      </div>

      {/* Turn  on/off  the current level */}
      {visiFloors.includes(storyIndex) ? (
        <div className="one-of-three-icons">
          <Tooltip title="Hides current level">
            <VisibilityIcon
              color="action"
              fontSize="small"
              onClick={() => turnOnOffSingleStory("Off")}
            />
          </Tooltip>
        </div>
      ) : (
        <div className="one-of-three-icons">
          <Tooltip title="Show current level">
            <VisibilityOffIcon
              color="action"
              fontSize="small"
              onClick={() => turnOnOffSingleStory("On")}
            />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default SingleStory;
