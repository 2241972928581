import React from "react";
import { useSelector } from "react-redux";

// importing Slice functions for handling state
import { getData } from "../../slicers/reinforcement-data/reinforcementSlice";

// importing app's components
import StoryDistributionChart from "./StoryDistributionChart";
import StorySpeedChart from "./StorySpeedChart";

// importing app's functionality
import {
  computeConstructionTime,
  computeConstructionEmissions,
  computeElementMaterialsPrice,
} from "../utilities";

const PriceAndSpeed = () => {
  // Getting reinforcement data
  const reinforcementData = useSelector(getData);
  // Getting data from where info is going to be extracted
  const dataToBeHandled = reinforcementData.dataToBeHandled;
  // Getting selected scenarios information
  const selectedScenarios = reinforcementData.selectedScenarios;
  // Getting prices and speed parameters
  const prices = reinforcementData.materialPrices;
  const speedParameters = reinforcementData.constructionSpeed;
  const emissionsParameters = reinforcementData.constructionEmissions;
  const priceByDiameter = reinforcementData.priceByDiameter;
  const materialPricesByDiameter = reinforcementData.materialPricesByDiameter;

  // Initialize totals
  const dataTotals = {
    cost_of_materials: { value: 0, text: "Cost of materials (M)" },
    construction_speed: { value: 0, text: "Construction time (days)" },
    co2_emissions: {
      value: 0,
      text: (
        <>
          CO<sub>2</sub> emissions (ton)
        </>
      ),
    },
  };

  // Computing totals
  Object.entries(selectedScenarios).forEach(([eletype, scenario]) => {
    // If there is not scenario do nothing
    if (!scenario) return;

    // As the reinforcement scenario can be changed by element, totals
    // must be computed by element
    let materialsCost = 0;
    let constructionTime = 0;
    let constructionEmissions = 0;
    let dataByElement = dataToBeHandled[eletype]["by_element"];

    // For each story
    for (const story in dataByElement) {
      if (dataByElement.hasOwnProperty(story)) {
        const elements = dataByElement[story];
        // For each element
        for (const ele in elements) {
          if (elements.hasOwnProperty(ele)) {
            // Materials is summed
            materialsCost += computeElementMaterialsPrice(
              elements[ele],
              priceByDiameter,
              prices,
              materialPricesByDiameter
            );

            // Construction time is summed
            constructionTime += computeConstructionTime(
              elements[ele],
              speedParameters
            );
            // Construction emissions is summed
            constructionEmissions += computeConstructionEmissions(
              elements[ele],
              emissionsParameters
            );
          }
        }
      }
    }

    // Getting total material cost
    dataTotals["cost_of_materials"].value += materialsCost;

    // Getting total construction duration
    dataTotals["construction_speed"].value +=
      Math.round(constructionTime * 10) / 10 / 8;

    // Getting total construction emissions
    dataTotals["co2_emissions"].value += constructionEmissions;
  });

  return (
    <>
      {/* Layout for reinforcement data   */}
      <div className="metrics-data-analysis">
        {/* Bar chart with by story distribution */}
        <div id="distribution-bar-chart">
          <StorySpeedChart
            domElementID="distribution-bar-chart"
            title="Construction scheduling (days)"
          />
        </div>

        {/* Rendering totals boxes */}
        <div className="metrics-items">
          <div className="data-totals">
            {Object.keys(dataTotals).map((datatype, index) => (
              <div className="metrics-totals" key={index}>
                {/* Total data */}
                <div className="totals-read-data">
                  {Math.round(dataTotals[datatype].value * 10) / 10}
                </div>
                {/* Name of data */}
                <div className="totals-text">{dataTotals[datatype].text}</div>
              </div>
            ))}
          </div>

          <div id="second-distribution-chart">
            <StoryDistributionChart
              domElementID="second-distribution-chart"
              chartType="cost_of_material"
              title="Material costs distribution ($ M)"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PriceAndSpeed;
