// Root imports
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// importing Slice functions for handling state
import { getGeometry } from "../../../slicers/project-geometry/geometrySlice";
import {
  getBuildingState,
  setBuildingState,
} from "../../../slicers/3D-state/buildingSlice";

// Third party functions and components
import LightModeIcon from "@mui/icons-material/LightMode";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Tooltip from "@mui/material/Tooltip";

const SingleGroup = ({ group }) => {
  // Hook to connect store
  const dispatch = useDispatch();
  // Getting current state
  const stories = useSelector(getGeometry).stories;
  const visibleFloors = useSelector(getBuildingState).visibleFloors;
  // Setting local state to handle changes on visibility
  const [visiFloors, setVisiFloors] = useState(visibleFloors);

  // Function to get the floor ids belonging to the group
  const getFloorIdsOfGroup = () => {
    // Getting names
    const floorNames = group.storeys;
    // Filtering and getting ids
    return stories
      .filter((story) => floorNames.includes(story.name))
      .map((story) => parseInt(story.id));
  };

  // Determine if the group is visible based on visibleFloors
  const storeysId = getFloorIdsOfGroup();
  const isGroupVisible = storeysId.every((id) => visiFloors.includes(id));

  // Method for turning on the group and turning off the rest
  const handleGroupOn = () => {
    // Get the story ids of the group
    const floorIds = getFloorIdsOfGroup();
    // Set visibleFloors to contain only the floors of the corresponding group
    dispatch(setBuildingState({ visibleFloors: floorIds }));
  };

  // Method for handling invidual level visualization options
  const turnOnOffSingleGroup = (action) => {
    let newVisibleFloors;
    // Updating building state with visible story if it is not visible yet
    if (action === "On" && !isGroupVisible) {
      // Copying visible floors
      newVisibleFloors = [...visiFloors];
      // Complementing the list of visible floors with those of the group
      storeysId.forEach((groupedStory) => {
        if (!newVisibleFloors.includes(groupedStory)) {
          newVisibleFloors.push(groupedStory);
        }
      });
      // Updating building state without the storeys if it was visible
    } else if (action === "Off" && isGroupVisible) {
      // Creating array with newVisible floors
      newVisibleFloors = visiFloors.filter((floor) =>
        !storeysId.includes(floor)
      );
    }
    // Updating state
    dispatch(setBuildingState({ visibleFloors: newVisibleFloors }));
  };

  // On changes of story visibility
  useEffect(() => {
    // Updating local state
    setVisiFloors(visibleFloors);
  }, [visibleFloors]);

  return (
    <div className="general-data-item">
      {/* Display name */}
      <div className="data-text-2icons">{group.name}</div>

      {/* Highlights the current level */}
      <div className="one-of-three-icons">
        <Tooltip title="Show current group">
          <LightModeIcon
            color="action"
            fontSize="small"
            onClick={handleGroupOn}
          />
        </Tooltip>
      </div>

      {/* Toggle the visibility */}
      {isGroupVisible ? (
        <div className="one-of-three-icons">
          <Tooltip title="Hide current group">
            <VisibilityIcon
              color="action"
              fontSize="small"
              onClick={() => turnOnOffSingleGroup("Off")}
            />
          </Tooltip>
        </div>
      ) : (
        <div className="one-of-three-icons">
          <Tooltip title="Show current group">
            <VisibilityOffIcon
              color="action"
              fontSize="small"
              onClick={() => turnOnOffSingleGroup("On")}
            />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default SingleGroup;
