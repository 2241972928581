// Root imports
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

// importing Slice functions for handling state
import {
  getData,
  setData,
  cleanDataByType,
  setScenario,
} from "../../../slicers/reinforcement-data/reinforcementSlice";

// Third party functions and components
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import VerifiedIcon from "@mui/icons-material/Verified";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";

// Names that will be shown at element type container
const eleTypesNames = {
  beams: "Beams data",
  joists: "Joists data",
  columns: "Columns data",
  walls: "Walls data",
};

const ElementsData = () => {
  // Hook for connecting store
  const dispatch = useDispatch();
  // Getting reinforcement data
  const reinforcementData = useSelector(getData);

  // Setting references
  const rebarDataRef = useRef(reinforcementData);
  const fileInputRefs = {
    beams: useRef(null),
    joists: useRef(null),
    columns: useRef(null),
    walls: useRef(null),
  };


  // Setting local state to handle changes on uploaded files
  const [fileNames, setFileNames] = useState({
    beams: undefined,
    joists: undefined,
    columns: undefined,
    walls: undefined,
  });

  // Setting local state to handle reinforcement scenario selection
  const [selectedOption, setSelectedOption] = useState({
    beams: rebarDataRef.current.selectedScenarios.beams,
    joists: rebarDataRef.current.selectedScenarios.joists,
    columns: rebarDataRef.current.selectedScenarios.columns,
    walls: rebarDataRef.current.selectedScenarios.walls,
  });

  // Method for handling file uploading
  const uploadData = (event, type) => {
    // So the event wont propagate beyond this component
    event.stopPropagation();

    const file = event.target.files[0];

    // If there is a file and it is a JSON file
    if (file && file.type === "application/json") {
      // Creating reader
      const reader = new FileReader();
      // Loading file
      reader.onload = (e) => {
        try {
          const json = JSON.parse(e.target.result);
          // Updating building state with uploaded data
          dispatch(setData({ [type]: json }));

          // Updating the file name state
          setFileNames((prevFileNames) => ({
            ...prevFileNames,
            [type]: file.name,
          }));

          // If there is an error parsing JSON
        } catch (err) {
          console.error("Error parsing JSON:", err);
        }
      };

      reader.readAsText(file);

      // If the file is not allowed
    } else {
      console.error("Please upload a valid JSON file.");
    }
  };

  // Method for cleaning data
  const cleanData = (event, type) => {
    // So the event wont propagate beyond this component
    event.stopPropagation();

    // Updating global state
    dispatch(cleanDataByType({ eleType: type }));

    // Updating local state
    setFileNames((prevFileNames) => ({
      ...prevFileNames,
      [type]: undefined,
    }));

    // Clear the file input ref
    if (fileInputRefs[type].current) {
      fileInputRefs[type].current.value = null;
    }
  };

  // On changes of elements data input update reference
  useEffect(() => {
    rebarDataRef.current = reinforcementData;
    // Updating local state
    setSelectedOption({
      beams: rebarDataRef.current.selectedScenarios.beams,
      joists: rebarDataRef.current.selectedScenarios.joists,
      columns: rebarDataRef.current.selectedScenarios.columns,
      walls: rebarDataRef.current.selectedScenarios.walls,
    });
  }, [reinforcementData]);

  // Method for handling select change option for scenarios
  const handleSelectChange = (event, type) => {
    // Updating local state
    setSelectedOption((prevSelectedOption) => ({
      ...prevSelectedOption,
      [type]: event.target.value,
    }));
    // Updating global state
    dispatch(setScenario({ eleType: type, scenario: event.target.value }));
  };

  return (
    // General container for element types visualization options
    <>
      {Object.keys(eleTypesNames).map((type, index) => (
        <div key={index}>
          <div className="sidebar-component">
            {/* General component with element types names and options */}
            <Accordion>
              {/* General title */}
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {/* General Upload data file Icon */}
                <div className="accordion-titlebar">
                  <div className="accordion-title">{eleTypesNames[type]}</div>

                  {/* Hidden file input to upload JSON */}
                  <input
                    type="file"
                    accept="application/json"
                    style={{ display: "none" }}
                    id={`upload-json-${type}`}
                    ref={fileInputRefs[type]}
                    onChange={(event) => uploadData(event, type)}
                  />
                  <label
                    htmlFor={`upload-json-${type}`}
                    className="accordion-title-icon"
                  >
                    <UploadFileIcon
                      color={
                        Object.keys(rebarDataRef.current[type]).length
                          ? "success"
                          : "action"
                      }
                      fontSize="small"
                      style={{ cursor: "pointer" }}
                    />
                  </label>

                  {/* Second icon only if a data file is already uploaded */}
                  {Object.keys(rebarDataRef.current[type]).length ? (
                    <div className="accordion-title-icon">
                      <VerifiedIcon
                        color={selectedOption[type] ? "primary" : "disabled"}
                        fontSize="small"
                      />
                    </div>
                  ) : undefined}
                </div>
              </AccordionSummary>

              {/* Details (file name and scenario selection) */}
              <AccordionDetails>
                <div className="data-with-select">
                  {/* Display file name */}
                  <div className="general-data-item">
                    <div className="data-text-1icon">
                      {!fileNames[type] ? "No file uploaded" : fileNames[type]}
                    </div>

                    {/* Icon for clean data */}
                    <div className="one-of-three-icons">
                      <CleaningServicesIcon
                        disabled={!fileNames[type]}
                        fontSize="small"
                        color={!fileNames[type] ? "disabled" : "action"}
                        onClick={(event) => cleanData(event, type)}
                      />
                    </div>
                  </div>

                  {/* Scenario selection */}
                  <select
                    className="scenario-selection"
                    value={selectedOption[type]}
                    disabled={!fileNames[type]}
                    onChange={(event) => handleSelectChange(event, type)}
                  >
                    {/* First option is just a title */}
                    <option className="title-scenario-options" value="">
                      Select reinforcement scenario
                    </option>

                    {/* Scenario selection */}
                    {Object.keys(rebarDataRef.current[type]).map(
                      (scenario, index) => (
                        <option
                          className="scenario-options"
                          key={index}
                          value={scenario}
                        >
                          {scenario}
                        </option>
                      )
                    )}
                  </select>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>

          <hr />
        </div>
      ))}
    </>
  );
};

export default ElementsData;
