// importing third party functions
import { createSlice } from "@reduxjs/toolkit";

// Import app's functionality
import {
  interpolateColor,
  computeConstructionTime,
  computeElementMaterialsPrice,
} from "../../content/utilities";

const addColorToData = (data, minValue, maxValue) => {
  // Data object will be updated with color key value with HEX notation
  return Object.fromEntries(
    Object.entries(data).map(([key, obj]) => {
      // Reading value
      const value = obj.value;
      // Interpolating color
      const color = interpolateColor(minValue, maxValue, value);
      return [key, { ...obj, color }];
    })
  );
};

export const reinforcementSlice = createSlice({
  // Project general slicer
  name: "reinforcementData",
  // By default the object start with empty children
  initialState: {
    // Reinforcement objects by element type
    beams: {},
    joists: {},
    columns: {},
    walls: {},
    // Object that will store the data to be displayed
    toBeDisplayed: {
      displayItem: undefined,
      title: undefined,
      max: undefined,
      min: undefined,
      byElement: {},
    },
    // Object with selected scenario keys
    selectedScenarios: {
      beams: undefined,
      joists: undefined,
      columns: undefined,
      walls: undefined,
    },
    // Active combination of scenarios
    combineScenariosByStory: false,
    // If there is a combination of scenarios by story, here will be stored
    scenarioCombinationByStory: {
      beams: undefined,
      joists: undefined,
      columns: undefined,
      walls: undefined,
    },
    // Object where data to be visualized will be stored, here scenario and
    // story combinations will be ready for visualization
    dataToBeHandled: {
      beams: {},
      joists: {},
      columns: {},
      walls: {},
    },
    // Object where prices information will be stored
    materialPrices: {
      concrete: 450000,
      rebar: 4000,
      connectors: 12000,
      heads: 18000,
    },
    // Active prices by diameter
    priceByDiameter: false,
    // If priceByDiameter is activated here data will be stored
    materialPricesByDiameter: {
      rebar: undefined,
      connectors: undefined,
      heads: undefined,
    },
    // Object where speed information will be stored
    constructionSpeed: {
      manHoursByKg: 0.006,
      manHoursByM3: 0.15,
      manHoursByM2: 0.15,
      crewSize: 4,
    },
    // Object where emissions information will be stored
    constructionEmissions: {
      emissionsByKg: 1.9,
      emissionsByM3: 336,
    },
  },
  // Reducer functions for controlling the state
  reducers: {
    // Update global geometry object
    setData: (state, action) => {
      // Here each key to update (action) will replace the original
      // key of the state

      // Getting keys to update
      const keysToUpdate = Object.keys(action.payload);

      // for each key to update it is replaced the values of the state object
      keysToUpdate.forEach((key) => {
        state[key] = action.payload[key];
      });
    },
    // Clear data for element type
    cleanDataByType: (state, action) => {
      // Reading element type
      const { eleType } = action.payload;

      // Cleaning state
      state[eleType] = {};
      state.selectedScenarios[eleType] = undefined;
      state.dataToBeHandled[eleType] = {};
      state.toBeDisplayed = {
        title: undefined,
        max: undefined,
        min: undefined,
        byElement: {},
      };
    },
    // Update selected scenario
    setScenario: (state, action) => {
      // Getting element type and scenario
      const elementType = action.payload.eleType;
      const scenario = action.payload.scenario;

      // Update state
      // Selected scenario
      state.selectedScenarios[elementType] = scenario;

      // Setting to false parameter that indicated
      // that there is a combination of scenarios by story
      state.scenarioCombinationByStory[elementType] = undefined;

      // By element information
      state.dataToBeHandled[elementType]["by_element"] =
        state[elementType][scenario].by_element;

      // By story information
      state.dataToBeHandled[elementType]["by_story"] =
        state[elementType][scenario].by_story;
    },
    // Update state with data to be displayed
    dataToDisplay: (state, action) => {
      // Key that indicated what is going to be displayed
      const displayItem = action.payload.displayItem;
      state.toBeDisplayed.displayItem = displayItem

      // Key to be found on global reinforcement data
      let dataKey;

      // Getting real name for key
      if (displayItem === "byWeight") {
        dataKey = "total_rebar_weight";
        state.toBeDisplayed.title = "Rebar weight (kgf)";
      } else if (displayItem === "byRebar") {
        dataKey = "bars_total";
        state.toBeDisplayed.title = "Rebar quantity (units)";
      } else if (displayItem === "byComplexity") {
        dataKey = "complexity_score";
        state.toBeDisplayed.title = "Element complexity score";
      } else if (displayItem === "bySpeed") {
        dataKey = "construction_time";
        state.toBeDisplayed.title = "Element construction time (hours)";
      } else if (displayItem === "byPrice") {
        dataKey = "element_price";
        state.toBeDisplayed.title = "Element price ($ M)";
      }

      // Initialize object and min-max values
      const dataToBeDisplayed = {};
      let maxValue = 0;
      let minValue = 1e10;

      // For each element type
      Object.keys(state.selectedScenarios).map((elementType) => {
        // Getting reinforcement scenario
        let scenario = state.selectedScenarios[elementType];

        // If there is a reinforcement scenario
        if (scenario && scenario !== "") {
          // Getting data inside scenario
          let dataByEleType = state.dataToBeHandled[elementType].by_element;

          // For each story in project
          for (const story in dataByEleType) {
            // For each element in story
            for (const element in dataByEleType[story]) {
              // Creating new key (union between element's name and story)
              const key = `${story} ${element}`;

              // Getting data to be visualized
              let dataValue;

              // For construction speed
              if (dataKey === "construction_time") {
                dataValue = computeConstructionTime(
                  dataByEleType[story][element],
                  state.constructionSpeed
                );
                // For element price
              } else if (dataKey === "element_price") {
                dataValue = computeElementMaterialsPrice(
                  dataByEleType[story][element],
                  state.priceByDiameter,
                  state.materialPrices,
                  state.materialPricesByDiameter
                );
                // Any other key
              } else {
                dataValue = dataByEleType[story][element][dataKey];
              }

              // Storing data
              dataToBeDisplayed[key] = { value: dataValue };
              // Computing max and min values
              maxValue = dataValue > maxValue ? dataValue : maxValue;
              minValue = dataValue < minValue ? dataValue : minValue;
            }
          }
        }
      });

      // Storing at global state
      state.toBeDisplayed.max = maxValue;
      state.toBeDisplayed.min = minValue;
      // Store with color scale
      state.toBeDisplayed.byElement = addColorToData(
        dataToBeDisplayed,
        minValue,
        maxValue
      );
    },
    // Update prices
    setPrices: (state, action) => {
      const itemToUpdate = action.payload.itemToUpdate;
      // Update state
      state.materialPrices[itemToUpdate] = action.payload.price;
    },
    // Update speed parameters
    setSpeedParameters: (state, action) => {
      const itemToUpdate = action.payload.itemToUpdate;
      // Update state
      state.constructionSpeed[itemToUpdate] = action.payload.value;
    },
    // Update speed parameters
    setEmissionsParameters: (state, action) => {
      const itemToUpdate = action.payload.itemToUpdate;
      // Update state
      state.constructionEmissions[itemToUpdate] = action.payload.value;
    },
    // Update a single story information at dataToBeHandled
    setStoryDataForScenarioCombination: (state, action) => {
      // Getting scenario, element type and story
      const scenario = action.payload.scenario;
      const eleType = action.payload.elementType;
      const story = action.payload.story;

      // Updating by_element key information
      state.dataToBeHandled[eleType].by_element[story] = {
        ...state[eleType][scenario].by_element[story],
      };

      // Updating by_story key information
      state.dataToBeHandled[eleType].by_story[story] = {
        ...state[eleType][scenario].by_story[story],
      };
    },
    // Update a single element information at dataToBeHandled
    setElementDataForScenarioCombination: (state, action) => {
      // Getting scenario, element type and story
      const scenario = action.payload.scenario;
      const eleType = action.payload.elementType;
      const story = action.payload.story;
      const element = action.payload.element;

      // Updating by_element key information
      state.dataToBeHandled[eleType].by_element[story][element] = {
        ...state[eleType][scenario].by_element[story][element],
      };
    },
  },
});

// Exporting the actions that will control the state
export const {
  setData,
  cleanDataByType,
  setScenario,
  dataToDisplay,
  setPrices,
  setSpeedParameters,
  setEmissionsParameters,
  setStoryDataForScenarioCombination,
  setElementDataForScenarioCombination,
} = reinforcementSlice.actions;

// Exporting function to get state
export const getData = (state) => state.reinforcementData;
// Exporting reducer
export default reinforcementSlice.reducer;
