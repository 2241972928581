import React, { useState } from "react";
import { useSelector } from "react-redux";

// importing Slice functions for handling state
import { getData } from "../../slicers/reinforcement-data/reinforcementSlice";

// Third party functions and components
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import VerifiedIcon from "@mui/icons-material/Verified";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";

// Third party library components
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SettingsIcon from "@mui/icons-material/Settings";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import Tooltip from "@mui/material/Tooltip";

// Import app's components
import InputParametersForm from "./MetricsInputParameters";
import Materials from "./MaterialsMetrics";
import PriceAndSpeed from "./PriceAndHoursMetrics";
import ReinfAnalysis from "./ReinforcementAnalysis";

// Array of components to cycle through
const components = [
  {
    component: Materials,
    name: "Materials",
    title: "Materials building metrics for selected reinforcement scenarios",
  },
  {
    component: PriceAndSpeed,
    name: "PriceAndSpeed",
    title: "Price building metrics and construction scheduling",
  },
  {
    component: ReinfAnalysis,
    name: "ReinfAnalysis",
    title: "Analysis for uploaded construction scenarios",
  },
];

const MetricsBox = ({ onClose }) => {
  // Getting reinforcement data from global state
  const reinforcementData = useSelector(getData);
  const selectedScenarios = reinforcementData.selectedScenarios;

  // Is some scenario selected?
  const isSomeScenarioSelected = Object.values(selectedScenarios).some(
    (sce) => sce
  );

  // State to keep track of the current component index
  // Starts with material metrics if some scenario is selected,
  // else starts with reinforcement analysis
  const [currentIndex, setCurrentIndex] = useState(
    isSomeScenarioSelected ? 0 : 2
  );

  // State to handle input parameters component rendering
  const [showInputParameters, setShowInputParameters] = useState(false);

  // Function to handle the next button click
  const handleNext = () => {
    setShowInputParameters(false);
    setCurrentIndex((prevIndex) => (prevIndex + 1) % components.length);
  };

  // Function to handle the previous button click
  const handlePrevious = () => {
    setShowInputParameters(false);
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? components.length - 1 : prevIndex - 1
    );
  };

  // Function to handle the settings icon click
  const handleSettingsClick = () => {
    setShowInputParameters(true);
  };

  // Get the current component to render
  const CurrentComponent = showInputParameters
    ? InputParametersForm
    : components[currentIndex].component;

  return (
    <div className="metrics-background">
      <div className="metrics-box">
        {/* Button to show the previous component */}
        <div className="next-metrics-box-icon" onClick={handlePrevious}>
          <Tooltip title="Previous metrics">
            <KeyboardDoubleArrowLeftIcon sx={{ fontSize: 40 }} />
          </Tooltip>
        </div>

        {/* Metrics content */}
        <div className="metrics-content">
          {/* Headline */}
          <div className="metrics-headline">
            {/* Box title */}
            <div className="metrics-title">
              {showInputParameters
                ? "Input parameters for metrics generation"
                : components[currentIndex].title}
            </div>

            {/* Icon for settings */}
            <div className="headline-icon">
              <SettingsIcon
                className="settings-icon"
                fontSize="large"
                color={showInputParameters ? "primary" : "inherit"}
                sx={{ fontSize: 20 }}
                onClick={handleSettingsClick}
              />
            </div>

            {/* Icon for closing box */}
            <div className="headline-icon">
              <HighlightOffIcon
                className="close-box-icon"
                fontSize="large"
                onClick={() => {
                  if (showInputParameters) {
                    setShowInputParameters(false);
                    setCurrentIndex(0);
                  } else {
                    onClose();
                  }
                }}
              />
            </div>
          </div>

          {/* Render the current component */}
          <CurrentComponent />
        </div>

        {/* Button to show the next component */}
        <div className="next-metrics-box-icon" onClick={handleNext}>
          <Tooltip title="Next metrics">
            <KeyboardDoubleArrowRightIcon sx={{ fontSize: 40 }} />
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default MetricsBox;
