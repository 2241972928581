// Third party functions
import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

// Third party components import
import Typography from "@mui/material/Typography";

// importing Slice functions for handling state
import { update, getSizes } from "../slicers/layout/layoutSlice.js";

// ProDes App components and features
import logo from "../static/logo.svg";
import UploadButton from "../content/upload_button.js";

// ProDes App styles
import "./css/layout.css";

// App's main component
const AppMainPage = () => {
  // Hook function for connecting the store
  const dispatch = useDispatch();
  // Getting the actual state from the slicer that controls layout variables
  const windowHeight = useSelector(getSizes).windowHeight;

  // Connecting with references the header and footer references
  const mainRef = useRef(null);

  // On mount, on unmount, on component's changes
  useEffect(() => {
    // Function that will update the new screen sizes
    const updateWindowSizes = () => {
      // Defining a new sizes object
      const newSizes = {
        windowHeight: window.innerHeight,
      };
      // updating state with the new sizes object
      dispatch(update(newSizes));
    };

    // On
    updateWindowSizes();
    window.addEventListener("resize", updateWindowSizes);

    // Cleanup code to run before unmounting the component
    return () => {
      window.removeEventListener("resize", updateWindowSizes);
    };

    // Dependencies matrix, the effect will be executed only when the state has been changed.
    // This is useful for optimizing performance as it avoids running the effect
    // unnecessarily if the values ​​haven't changed.
  }, [dispatch]);

  // Rendering the component
  return (
    <div>
      {/* main container */}
      <div
        id="main-container"
        ref={mainRef}
        style={{
          minHeight: windowHeight,
        }}
      >
        {/* ProDes logo and name for >Medium size screens */}
        {/* logo and button container */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* logo */}
          <img
            src={logo}
            className="logo"
            alt="logo"
            style={{ height: "250px", marginBottom: "20px" }}
          />

          <Typography
            id="innertext"
            variant="h1"
            noWrap
            component="a"
            sx={{
              fontWeight: 700,
              color: "white",
              textDecoration: "none",
              display: { xs: "none", md: "flex" },
            }}
          >
            Welcome to StructuBIM
          </Typography>

          {/* button */}
          <UploadButton />
        </div>
      </div>
    </div>
  );
};

export default AppMainPage;
