// Root imports
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// importing Slice functions for handling state
import {
  getBuildingState,
  setBuildingState,
} from "../../../slicers/3D-state/buildingSlice";

// Third party functions and components
import LightModeIcon from "@mui/icons-material/LightMode";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Tooltip from "@mui/material/Tooltip";

// Names that will be shown at element type container
const eleTypesNames = {
  B: "Beams (B, V)",
  N: "Joists (J, N)",
  C: "Columns (C)",
  W: "Walls (W)",
};

const SingleElementType = ({ eleType }) => {
  // Hook to connect store
  const dispatch = useDispatch();
  // Getting current state
  const visibleEleTypes = useSelector(getBuildingState).visibleElements;
  // Setting local state to handle changes on visibility
  const [visiElems, setVisiElems] = useState(visibleEleTypes);
  // Checking if eleType is visible
  const isEleTypeVisible = visibleEleTypes.includes(eleType);
  // Getting name to render
  const eleTypeName = eleTypesNames[eleType];

  // Method for turning on the element type and turning off the rest
  const handleEleTypeOn = () => {
    // Set visibleFloors to contain only the floors of the corresponding group
    dispatch(setBuildingState({ visibleElements: [eleType] }));
  };

  // Method for handling invidual element type visualization options
  const turnOnOffSingleEleType = (action) => {
    let newVisibleEleTypes;
    // Updating building state with visible element type if it is not visible yet
    if (action === "On" && !isEleTypeVisible) {
      // Complementing the visible types
      newVisibleEleTypes = [...visiElems, eleType];
      // Updating building state without the storeys if it was visible
    } else if (action === "Off" && isEleTypeVisible) {
      // CFiltering visible element types without the eleType
      newVisibleEleTypes = visiElems.filter((type) => type !== eleType);
    }
    // Updating state
    dispatch(setBuildingState({ visibleElements: newVisibleEleTypes }));
  };

  // On changes of element types visibility
  useEffect(() => {
    // Updating local state
    setVisiElems(visibleEleTypes);
  }, [visibleEleTypes]);

  return (
    <div className="general-data-item">
      {/* Display name */}
      <div className="data-text-2icons">{eleTypeName}</div>

      {/* Highlights the current element type */}
      <div className="one-of-three-icons">
        <Tooltip title="Show element type">
          <LightModeIcon
            color="action"
            fontSize="small"
            onClick={handleEleTypeOn}
          />
        </Tooltip>
      </div>

      {/* Toggle the visibility */}
      {isEleTypeVisible ? (
        <div className="one-of-three-icons">
          <Tooltip title="Hide element type">
            <VisibilityIcon
              color="action"
              fontSize="small"
              onClick={() => turnOnOffSingleEleType("Off")}
            />
          </Tooltip>
        </div>
      ) : (
        <div className="one-of-three-icons">
          <Tooltip title="Show element type">
            <VisibilityOffIcon
              color="action"
              fontSize="small"
              onClick={() => turnOnOffSingleEleType("On")}
            />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default SingleElementType;
