// Root imports
import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// importing Slice functions for handling state
import { getGeometry } from "../../../slicers/project-geometry/geometrySlice";
import {
  setBuildingState,
  getBuildingState,
} from "../../../slicers/3D-state/buildingSlice";

// Third party functions and components
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Tooltip from "@mui/material/Tooltip";

// Importing ProDes app components
import SingleStory from "./SingleStory";

// ProDes styles import
import "../../css/content.css";

const GeneralStoreys = () => {
  // Hook for connecting store
  const dispatch = useDispatch();
  // Getting general keys from geometry and building states
  const stories = useSelector(getGeometry).stories;
  const visibleFloors = useSelector(getBuildingState).visibleFloors;

  // Setting local state to handle changes
  const [visiFloors, setVisiFloors] = useState(visibleFloors);

  // Getting number of storeys
  const nStoreys = stories.length;
  // Creating an array with the story information objects ids
  const allStoreys = Array.from({ length: nStoreys }, (_, i) => i);

  // Method for handling general visualization of stories
  const turnOnOffStoreys = (event, action) => {
    // So the event wont propagate beyond this component
    event.stopPropagation();

    // Updating building state with all visible floors
    if (action === "On") {
      dispatch(setBuildingState({ visibleFloors: allStoreys }));
      // Updating building state with any visible storeys
    } else {
      dispatch(setBuildingState({ visibleFloors: [] }));
    }
  };

  // On changes of story visibility
  useEffect(() => {
    // Updating local state
    setVisiFloors(visibleFloors);
  }, [visibleFloors]);

  return (
    // General container for story names and visualization options
    <div>
      {/* General component with story names and options */}
      <Accordion>
        {/* General title */}
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          {/* General Visibility Icon */}
          <div className="accordion-titlebar">
            <div className="accordion-title">Storeys</div>
            <div className="accordion-title-icon">
              {/* If all storeys are visible */}
              {visiFloors.length === nStoreys ? (
                <Tooltip title="Hide all storeys">
                  <VisibilityIcon
                    color="action"
                    fontSize="small"
                    onClick={(event) => turnOnOffStoreys(event, "Off")}
                  />
                </Tooltip>
              ) : (
                <Tooltip title="Show all storeys">
                  <VisibilityOffIcon
                    color="action"
                    fontSize="small"
                    onClick={(event) => turnOnOffStoreys(event, "On")}
                  />
                </Tooltip>
              )}
            </div>
          </div>
        </AccordionSummary>

        {/* Subtitle and general visibility controls (activate/deactivate all floors) */}
        <AccordionDetails>
          {/* This is the main container and contains a text field, a layers icon, a lightmode icon and a visibility icon */}
          <div className="items-group">
            {/* for each story will be created the text field and visibility controls */}
            {stories.map((story, index) => (
              <div key={index}>
                {/* Creating level component */}
                <SingleStory storyIndex={index} />
              </div>
            ))}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default GeneralStoreys;
