import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import projectStore from "./stores/store.js";
import App from "./layout/app.js";

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import "./index.css";
import { theme } from "./theme.js";

const root = createRoot(document.getElementById("root"));

// Rendering the application
root.render(
  <React.StrictMode>
    {/* Provide the global state management store to the main component of the application */}
    <Provider store={projectStore}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
      <CssBaseline />
    </Provider>
  </React.StrictMode>
);
