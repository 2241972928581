import React, { useEffect } from "react";
import { useSelector } from "react-redux";

// Third party libraries
import * as echarts from "echarts";

// importing Slice functions for handling state
import { getData } from "../../slicers/reinforcement-data/reinforcementSlice";

// importing app's functions
import {
  prepareDataForChart,
  combineValues,
  convertValuesToPercentage,
} from "../utilities";

// Names that will be shown at element type container
const eleTypes = {
  beams: "Beams",
  joists: "Joists",
  columns: "Columns",
  walls: "Walls",
};

const PieChartByKey = ({ domElementID, chartKey }) => {
  // Getting reinforcement data
  const reinforcementData = useSelector(getData);

  // Getting selected scenarios information
  const selectedScenarios = reinforcementData.selectedScenarios;

  // Array where chart Data will be stored
  let chartData = [];

  // Chart title
  let chartTitle;
  let seriesTitle;

  // Getting data arrays for chart
  Object.entries(selectedScenarios).forEach((byElement, index) => {
    prepareDataForChart(
      reinforcementData.dataToBeHandled,
      chartData,
      chartKey,
      eleTypes,
      byElement[0],
      byElement[1]
    );
  });

  // If the data comes from by diameter objects must be combined
  if (chartKey !== "concrete_volume") {
    // Combined data
    chartData = combineValues(chartData);
    // Percentage form converted data
    chartData = convertValuesToPercentage(chartData);
    // Set chart title
    chartTitle = "Rebar weight by diameter";
    seriesTitle = "Rebar weight";

    // Just set chart title
  } else {
    // Percentage form converted data
    chartData = convertValuesToPercentage(chartData);
    chartTitle = "Concrete volume by type of element";
    seriesTitle = "Concrete volume";
  }

  // On mount
  useEffect(() => {
    // Getting DOM element
    const chartDom = document.getElementById(domElementID);

    // Initializing bar chart
    const chart = echarts.init(chartDom);

    // Pie chart
    const option = {
      title: {
        text: chartTitle,
        left: "center",
        top: "10px",
        textStyle: {
          fontSize: "1rem",
          fontWeight: "bold",
          color: "black",
          fontFamily:
            '"Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif',
        },
      },
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b}: {c}%",
      },
      legend: {
        orient: "horizontal",
        left: "center",
        bottom: 0,
        textStyle: {
          fontSize: "0.65rem",
          fontFamily:
            '"Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif',
        },
      },
      series: [
        {
          name: seriesTitle,
          type: "pie",
          radius: "50%",
          center: ["50%", "50%"],
          data: chartData,
          label: {
            fontSize: "0.65rem",
            fontFamily:
              '"Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif',
            formatter: "{b}: {d}%",
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
      grid: {
        top: "15%",
      },
    };

    // Setting chart configuration
    chart.setOption(option);

    // On dismount cleaning chart
    return () => {
      chart.dispose();
    };
  }, [reinforcementData]);

  return <div id={domElementID}></div>;
};

export default PieChartByKey;
