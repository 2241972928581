// Root imports
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// importing Slice functions for handling state
import {
  getBuildingState,
  setBuildingState,
} from "../../../slicers/3D-state/buildingSlice";

// Third party functions and components
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Tooltip from "@mui/material/Tooltip";

// Importing ProDes app components
import SingleElementType from "./SingleElementType";

const ElementTypes = () => {
  // Hook for connecting store
  const dispatch = useDispatch();
  // Getting current state
  const visibleEleTypes = useSelector(getBuildingState).visibleElements;
  // Setting local state to handle changes on visibility
  const [visiElems, setVisiElems] = useState(visibleEleTypes);

  // Method for handling general visualization of stories
  const turnOnOffElements = (event, action) => {
    // So the event wont propagate beyond this component
    event.stopPropagation();

    // Updating building state with all visible floors
    if (action === "On") {
      dispatch(setBuildingState({ visibleElements: ["B", "N", "C", "W"] }));
      // Updating building state with any visible storeys
    } else {
      dispatch(setBuildingState({ visibleElements: [] }));
    }
  };

  // On changes of element types visibility
  useEffect(() => {
    // Updating local state
    setVisiElems(visibleEleTypes);
  }, [visibleEleTypes]);

  return (
    // General container for element types visualization options
    <div>
      {/* General component with element types names and options */}
      <Accordion>
        {/* General title */}
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          {/* General Visibility Icon */}
          <div className="accordion-titlebar">
            <div className="accordion-title">Types of Elements</div>
            <div className="accordion-title-icon">
              {/* If all element types are visible */}
              {visiElems.length == 4 ? (
                <Tooltip title="Hide all elements">
                  <VisibilityIcon
                    color="action"
                    fontSize="small"
                    onClick={(event) => turnOnOffElements(event, "Off")}
                  />
                </Tooltip>
              ) : (
                <Tooltip title="Show all elements">
                  <VisibilityOffIcon
                    color="action"
                    fontSize="small"
                    onClick={(event) => turnOnOffElements(event, "On")}
                  />
                </Tooltip>
              )}
            </div>
          </div>
        </AccordionSummary>

        {/* General visibility controls (activate/deactivate element type) */}
        <AccordionDetails>
          <div>
            {["B", "N", "C", "W"].map((type, index) => (
              <div key={index}>
                <SingleElementType eleType={type} />
              </div>
            ))}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default ElementTypes;
