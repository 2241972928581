import React, { useState, useEffect, memo } from "react";
import { useDispatch, useSelector } from "react-redux";

// importing Slice functions for handling state
import { getBuildingState } from "../../slicers/3D-state/buildingSlice.js";
import {
  getData,
  dataToDisplay,
  setElementDataForScenarioCombination,
} from "../../slicers/reinforcement-data/reinforcementSlice.js";

// ProDes css files, components and functionality
import {
  computeConstructionTime,
  computeElementMaterialsPrice,
} from "../utilities.js";

// ProDes style sheets
import "../css/content.css";

const elementData = [
  { text: "Concrete volume (m³)", key: "vol_concreto" },
  { text: "Rebar weight (kg)", key: "total_rebar_weight" },
  { text: "Rebar quantity (units)", key: "bars_total" },
  { text: "Connectors quantity (units)", key: "connectors_total" },
  { text: "Mech. heads quantity (units)", key: "heads_total" },
  { text: "Complexity score", key: "complexity_score" },
  { text: "Construction time (hours)", key: "construction_speed" },
  { text: "Price ($ M)", key: "element_price" },
];

const elementTypeMap = {
  B: "beams",
  V: "beams",
  J: "joists",
  N: "joists",
  C: "columns",
  W: "walls",
};

// This component renders the sidebar with the selected element information
const SelectedElement = memo(() => {
  // Function to update global state
  const dispatch = useDispatch();

  // Getting the actual state from the slicers
  const modelState = useSelector(getBuildingState);
  const reinforcementData = useSelector(getData);

  // Setting local states to handle reinforcement scenario selection
  const [selectedOption, setSelectedOption] = useState(undefined);
  const [showSelect, setShowSelect] = useState(false);

  // On changes of local state of scenario selection
  useEffect(() => {
    // If there is not a selected option yet do nothing
    if (!selectedOption) return;

    // Update global state
    dispatch(
      setElementDataForScenarioCombination({
        scenario: selectedOption,
        elementType: realType,
        story: story,
        element: element,
      })
    );

    // If there is a visual report activated
    if (!reinforcementData.toBeDisplayed.displayItem) return;

    // Update global state
    dispatch(
      dataToDisplay({
        displayItem: reinforcementData.toBeDisplayed.displayItem,
      })
    );
  }, [selectedOption]);

  // If for some reason props arrives undefined
  if (!modelState.selectedElementRealName) return null;

  // Getting prices and speed parameters
  const prices = reinforcementData.materialPrices;
  const priceByDiameter = reinforcementData.priceByDiameter;
  const materialPricesByDiameter = reinforcementData.materialPricesByDiameter;

  // Destructure the data object to get individual properties
  const { element, elementType, story } = modelState.selectedElementRealName;
  // Getting element type key that will be looked for at reinforcementData
  const realType = elementTypeMap[elementType];
  // Getting element type info
  const eleTypeInfo = reinforcementData.dataToBeHandled[realType];

  // Getting element info if it exists
  const eleInfo =
    eleTypeInfo.hasOwnProperty("by_element") &&
    eleTypeInfo.by_element.hasOwnProperty(story) &&
    eleTypeInfo.by_element[story].hasOwnProperty(element)
      ? eleTypeInfo.by_element[story][element]
      : null;

  // Concatenate dynamic title
  const titleText = `${element} - ${story}`;

  // Method for getting the value that will be rendered
  const getValue = (key) => {
    // For construction speed
    if (key === "construction_speed") {
      return computeConstructionTime(
        eleInfo,
        reinforcementData.constructionSpeed
      );
    }
    // For element price
    if (key === "element_price") {
      return computeElementMaterialsPrice(
        eleInfo,
        priceByDiameter,
        prices,
        materialPricesByDiameter
      );
    }
    // For any other
    return Math.round(eleInfo[key] * 10) / 10;
  };

  // Rendering the component
  return (
    <div className="sidebar-container">
      {/* Sidebar title */}
      <div className="sidebar-title"> ELEMENT INFORMATION</div>
      <div className="sidebar-title"> {titleText}</div>

      {/* Just a horizontal line */}
      <hr />

      {/* Element info */}
      {eleInfo ? (
        <div className="element-data-sidebar">
          <div className="element-sidebar-title">Element metrics</div>

          {/* Mapping over elementData to render each item */}
          {elementData.map(
            (item, index) =>
              (item.key === "element_price" ||
                item.key === "construction_speed" ||
                eleInfo[item.key] > 0) && (
                <div key={index} className="element-sidebar-data">
                  <div className="element-sidebar-text">{item.text}</div>
                  <div className="element-sidebar-value">
                    {getValue(item.key)}
                  </div>
                </div>
              )
          )}
        </div>
      ) : (
        <div className="element-sidebar-title no-data">
          No scenario selected
        </div>
      )}

      {/* Just a horizontal line */}
      <hr />

      {/* Button to activate scenario selection by element */}
      {!showSelect && eleInfo ? (
        <div className="element-data-sidebar">
          <div className="metrics-button">
            <div className="primary-button" onClick={() => setShowSelect(true)}>
              Change reinforcement scenario for this element
            </div>
          </div>
        </div>
      ) : null}

      {/* Scenario selection by element */}
      {showSelect && eleInfo ? (
        <div className="element-data-sidebar">
          <div className="element-sidebar-title">
            Select reinforcement scenario
          </div>

          <select
            className="sidebar-select"
            value={selectedOption}
            onChange={(event) => setSelectedOption(event.target.value)}
          >
            <option
              className="title-scenario-options"
              value=""
              disabled={selectedOption}
            >
              Select scenario
            </option>
            {Object.keys(reinforcementData[realType]).map(
              (scenario, scenarioIndex) => (
                <option
                  className="scenario-options"
                  key={scenarioIndex}
                  value={scenario}
                >
                  {scenario}
                </option>
              )
            )}
          </select>
        </div>
      ) : null}

      {/* Just a horizontal line */}
      <hr />
    </div>
  );
});

export default SelectedElement;
