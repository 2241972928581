import React from "react";
import JSZip from "jszip";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { AddCircle } from "@mui/icons-material";
import { IconButton } from "@mui/material";

// Importing ProDes components and functionality
import Project from "../project/project.js";

// Slice functions for handling state
import { setGeometry } from "../slicers/project-geometry/geometrySlice.js";
import { setBuildingState } from "../slicers/3D-state/buildingSlice.js";

const UploadButton = () => {
  // Definitions
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Listing the component's buttons
  const createButton = {
    id: "button-newProject",
    text: "CREATE NEW",
    icon: AddCircle,
    handler: handleNewProject,
    isInput: true,
    allowedFormats: ".zip",
    id_input: "new-project",
  };

  // Function to handle the uploading of ProDet's files to create a new project
  async function handleNewProject(event) {
    // Get the first file from the file input
    const file = event.target.files[0];

    // Check if a file is selected
    if (file) {
      // Create a new FileReader to read the file content
      const reader = new FileReader();

      // Define what happens when the file is fully loaded
      reader.onload = async (e) => {
        // Get the file content as an ArrayBuffer
        const arrayBuffer = e.target.result;
        try {
          // Use JSZip to load and read the zip file
          const zip = await JSZip.loadAsync(arrayBuffer);
          const projectData = {};
          const filePromises = [];

          // Iterate through each file in the zip archive
          zip.forEach((relativePath, zipEntry) => {
            // Check if the file is a JSON file
            if (zipEntry.name.endsWith(".json")) {
              // Read the JSON file content as a string
              const promise = zipEntry.async("string").then((content) => {
                // Remove the .json extension from the file name and parse the content as JSON
                projectData[zipEntry.name.replace(/\.json$/, "")] =
                  JSON.parse(content);
              });
              // Store the promise for later use
              filePromises.push(promise);
            }
          });

          // Wait for all file reading promises to complete
          await Promise.all(filePromises);
          
          // Building project geometry
          const projectGeometry = new Project(projectData).serialize();

          // Setting geometry state
          dispatch(setGeometry(projectGeometry));

          // Setting at building state that all storeys will be visible
          // Getting number of storeys
          const nStoreys = projectGeometry.stories.length;
          // Creating an array with the story information objects ids
          const allStoreys = Array.from({ length: nStoreys }, (_, i) => i);
          // Getting number of axes
          const nAxes = projectGeometry.axes.length;
          // Creating an array with the story information objects ids
          const allAxes = Array.from({ length: nAxes }, (_, i) => i);
          // Creating an array with element types
          const allEleTypes = ["B", "N", "C", "W"];

          // Updating building state with all visibility options turned on
          dispatch(
            setBuildingState({
              visibleFloors: allStoreys,
              visibleAxes: allAxes,
              visibleElements: allEleTypes,
            })
          );

          // Navigate to the project route
          navigate("/project");
        } catch (error) {
          // Log any errors that occur during the file reading process
          console.error("Error reading zip file:", error);
        }
      };

      // Read the file content as an ArrayBuffer
      reader.readAsArrayBuffer(file);
    }
  }

  return (
    <>
      {/* // Buttons to load files */}
      <label key={createButton.id} htmlFor={createButton.id_input}>
        <input
          type="file"
          id={createButton.id_input}
          name={createButton.id_input}
          accept={createButton.allowedFormats}
          style={{ display: "none" }}
          onChange={createButton.handler}
        />
        <IconButton
          className="projectsButtons"
          id={createButton.id}
          variant="contained"
          component="span"
        >
          <div className="containerProjectsButtons">
            <div className="dummyDivProjectsButtons"></div>
            <div>
              <span>{createButton.text}</span>
            </div>
            <div>
              <createButton.icon />
            </div>
          </div>
        </IconButton>
      </label>
    </>
  );
};

export default UploadButton;
