// Root imports
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// importing Slice functions for handling state
import {
  getData,
  setData,
  dataToDisplay,
} from "../../../slicers/reinforcement-data/reinforcementSlice";

// Third party functions and components
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import InvertColorsOffIcon from "@mui/icons-material/InvertColorsOff";
import Tooltip from "@mui/material/Tooltip";

// AppComponents
import MetricsBox from "../../metrics/GeneralMetrics";

const displayItems = {
  byWeight: "Rebar weight",
  byRebar: "Rebar quantity",
  byComplexity: "Element complexity",
  bySpeed: "Element construction time",
  byPrice: "Element price",
};

const displayReports = {
  all: "Visual reports",
};

const DisplayOptions = () => {
  // Hook for connecting store
  const dispatch = useDispatch();
  // Getting reinforcement data
  const reinforcementData = useSelector(getData);

  // Setting local state for handling local states
  const [areScenariosSelected, verifyScenarios] = useState(false);
  const [isDataUploaded, verifyData] = useState(false);
  const [showBox, setShowBox] = useState(false);

  // Method for turning off visualization of reinforcement data
  const turnOffVisualization = (event) => {
    // So the event wont propagate beyond this component
    event.stopPropagation();
    // Cleaning state
    dispatch(
      setData({
        toBeDisplayed: {
          displayItem: undefined,
          title: undefined,
          max: undefined,
          min: undefined,
          byElement: {},
        },
      })
    );
  };

  // On changes of element types visibility
  useEffect(() => {
    // Verifying if there is some data uploaded
    const someDataUploaded = !(
      !Object.values(reinforcementData.beams).length &&
      !Object.values(reinforcementData.joists).length &&
      !Object.values(reinforcementData.columns).length &&
      !Object.values(reinforcementData.walls).length
    );

    // Updating local state
    verifyData(someDataUploaded);

    // Verifying if there is some scenario selected
    const someScenarioSelected = Object.values(
      reinforcementData.selectedScenarios
    ).some((value) => value !== undefined && value !== null && value !== "");

    // Updating local state
    verifyScenarios(someScenarioSelected);
  }, [reinforcementData]);

  return (
    // General container for element types visualization options
    <>
      <div className="metrics-button">
        <div
          className="primary-button"
          onClick={() => {
            if (isDataUploaded) setShowBox(true);
          }}
        >
          Display building metrics
        </div>
      </div>

      {showBox && <MetricsBox onClose={() => setShowBox(false)} />}

      {Object.keys(displayReports).map((type, index) => (
        <div key={index}>
          <div className="sidebar-component">
            {/* General component with element types names and options */}
            <Accordion>
              {/* General title */}
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {/* General Visibility Icon */}
                <div className="accordion-titlebar">
                  <div className="accordion-title">{displayReports[type]}</div>
                  <div className="accordion-title-icon">
                    <Tooltip title="Restore original view">
                      <InvertColorsOffIcon
                        color="action"
                        fontSize="small"
                        onClick={(event) => {
                          turnOffVisualization(event);
                        }}
                      />
                    </Tooltip>
                  </div>
                </div>
              </AccordionSummary>

              {/* General visibility controls (activate/deactivate element type) */}
              <AccordionDetails>
                {Object.keys(displayItems).map((item, index) => (
                  <div key={index} className="general-data-item">
                    {/* Visual report name */}
                    <div className="data-text-1icon">{displayItems[item]}</div>

                    {/* Generate visual report icon */}
                    <Tooltip title="Generate visual report">
                      <AccountTreeIcon
                        className="one-of-three-icons"
                        color={areScenariosSelected ? "primary" : "disabled"}
                        fontSize="small"
                        onClick={() => {
                          if (areScenariosSelected) {
                            dispatch(dataToDisplay({ displayItem: item }));
                          }
                        }}
                      />
                    </Tooltip>
                  </div>
                ))}
              </AccordionDetails>
            </Accordion>
          </div>

          <hr />
        </div>
      ))}
    </>
  );
};

export default DisplayOptions;
