import React, { useState } from "react";

// Third party components
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import logo from "../static/logo.svg";

// ProDes css files, components and functionality
import "./css/header.css";

const pages = ["PLANS & PRICING", "HELP AND TUTORIALS", "About us"];
const settings = ["Profile", "Account", "Dashboard", "Logout"];

function AppBarHeader() {
  /*
  This function generates application's header 

  Returns:
  --------
  Materials UI AppBar component with main pages and user's settings
  
  */
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  // open navigation menu for small screens
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  // open user settings menu
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  // close navigation menu for small screens
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  // close user settings menu
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  // general style for bars
  const barStyle = {
    backgroundColor: "#2d2e30",
  };

  // general style for texts
  const textStyle = {
    fontWeight: 700,
    color: "white",
    textDecoration: "none",
    "&:hover": {
      color: "#757575",
    },
  };

  return (
    // Main app bar
    <AppBar position="static" id="main-bar" sx={{ ...barStyle }}>
      {/* tool bar with general routes */}
      <Toolbar disableGutters id="main-bar" sx={barStyle}>
        {/* Menu bar items for large screens */}
        <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
          {/* ProDes logo and name for >Medium size screens */}
          {/* logo */}
          <a href="./">
            <img src={logo} className="logo" alt="logo" />
          </a>

          {/* For each page, it is rendered the button */}
          {pages.map((page) => (
            <MenuItem
              key={page}
              onClick={handleCloseNavMenu}
              sx={{ display: "block" }}
            >
              {/*Including text of the page*/}
              <Typography
                component="a"
                sx={{ ...textStyle }}
                textAlign="center"
              >
                {page}
              </Typography>
            </MenuItem>
          ))}
        </Box>

        {/* Menu bar items for small screens */}
        <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>

          {/* left menu */}
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: "block", md: "none" },
            }}
          >
            {/* For each page, it is rendered the button */}
            {pages.map((page) => (
              <MenuItem
                key={page}
                onClick={handleCloseNavMenu}
                sx={{ ...textStyle}}
              >
                <Typography id="innertext-menu" textAlign="center">
                  {page}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>

        {/* ProDes logo and name for <Small size screens */}
        {/* logo */}
        <img src={logo} className="logo-small-screens" alt="logo" />
        {/* name */}
        <Typography
          variant="h5"
          noWrap
          component="a"
          href="/main-page"
          sx={{
            ...textStyle,
            display: { xs: "flex", md: "none" },
            flexGrow: 1,
          }}
        >
          ProDes
        </Typography>

        <Box sx={{ flexGrow: 0 }}>
          <Tooltip>
            <IconButton onClick={handleOpenUserMenu}>
              <Avatar
                alt="UserName"
                src="/static/images/avatar/2.jpg"
                sx={{
                  height: "1.8rem",
                  width: "1.8rem",
                  fontSize: "0.95rem",
                }}
              />
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: "30px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings.map((setting) => (
              <MenuItem key={setting} onClick={handleCloseUserMenu}>
                <Typography id="innertext-menu" textAlign="center">
                  {setting}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Toolbar>
      {/* horizontal line divider */}
      <hr style={{ margin: "0" }} />
    </AppBar>
  );
}
export default AppBarHeader;
