import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// Third party components
import TuneIcon from "@mui/icons-material/Tune";

// importing Slice functions for handling state
import {
  getData,
  setData,
  setScenario,
  dataToDisplay,
  setStoryDataForScenarioCombination,
} from "../../slicers/reinforcement-data/reinforcementSlice";

// Names that will be shown at element type container
const eleTypes = {
  beams: "Beams",
  joists: "Joists",
  columns: "Columns",
  walls: "Walls",
};

const SettingsScenarioSelection = () => {
  // Setting dispatch function
  const dispatch = useDispatch();

  // Getting reinforcement data
  const reinforcementData = useSelector(getData);
  // Setting references
  const rebarDataRef = useRef(reinforcementData);

  // Getting scenarios information
  const selectedScenarios = reinforcementData.selectedScenarios;
  const scenariosByStory = reinforcementData.scenarioCombinationByStory;

  // Checking if some scenario was selected
  const isSomeScenarioSelected = Object.values(selectedScenarios).some(
    (sce) => sce !== undefined
  );

  // Setting local states to handle reinforcement scenario selection
  const [selectedOption, setSelectedOption] = useState({
    beams: selectedScenarios.beams,
    joists: selectedScenarios.joists,
    columns: selectedScenarios.columns,
    walls: selectedScenarios.walls,
  });

  // Setting local state to handle reinforcement scenario selection
  const [selectedOptionByStory, setSelectedOptionByStory] = useState({
    beams: scenariosByStory.beams,
    joists: scenariosByStory.joists,
    columns: scenariosByStory.columns,
    walls: scenariosByStory.walls,
  });

  // Local state to handle changes between global scenario or by story
  const [scenarioByStory, setScenarioByStory] = useState(
    reinforcementData.combineScenariosByStory
  );

  // Method for handling select change option for scenarios
  const handleSelectChange = (event, type, story) => {
    // if there is a Story to update
    if (story) {
      // Updating local state for scenarios by story
      setSelectedOptionByStory((prevSelectedOptionByStory) => ({
        ...prevSelectedOptionByStory,
        [type]: {
          ...prevSelectedOptionByStory[type],
          [story]: event.target.value,
        },
      }));

      // Updating global state with story info
      dispatch(
        setStoryDataForScenarioCombination({
          scenario: event.target.value,
          story: story,
          elementType: type,
        })
      );
    } else {
      // Updating local state for general scenarios
      setSelectedOption((prevSelectedOption) => ({
        ...prevSelectedOption,
        [type]: event.target.value,
      }));

      // Updating global state for general scenarios
      dispatch(setScenario({ eleType: type, scenario: event.target.value }));
    }

    // If there is a visual report activated
    if (!rebarDataRef.current.toBeDisplayed.displayItem) return;

    // Update global state
    dispatch(
      dataToDisplay({
        displayItem: reinforcementData.toBeDisplayed.displayItem,
      })
    );
  };

  // Method for getting story names
  const getStoryNames = () => {
    // Initialize variables
    let storyNames;
    let firstScenario;
    let extractStoryNameFrom;

    // Getting the first element information
    for (let type of Object.keys(eleTypes)) {
      if (selectedOption[type]) {
        firstScenario = Object.keys(rebarDataRef.current[type])[0];
        extractStoryNameFrom = rebarDataRef.current[type][firstScenario];
        break;
      }
    }

    // If there is information to extract
    if (extractStoryNameFrom) {
      // Getting entries from data
      storyNames = Object.entries(extractStoryNameFrom.by_story);

      // Sorting by story heights
      storyNames.sort((a, b) => a[1].story_height - b[1].story_height);

      // Mapping to get the first value of each entry
      storyNames = storyNames.map((entry) => entry[0]);
    }

    return storyNames;
  };

  // Method for generating selects by story and general
  const generateSelects = (byStory = false, storyIndex = 0, story = null) => {
    return (
      // Select scenario option by element type
      // If it is byStory then story name will be rendered
      <div className="settings-inputs" key={storyIndex}>
        {byStory ? (
          <div className="settings-story-name">
            {storyIndex === 0 ? (
              <div className="settings-text">Story name</div>
            ) : null}
            <div className="settings-input">{story}</div>
          </div>
        ) : null}

        {/* For each element type */}
        {/* If  it is the fist element the element type name will be rendered */}
        {Object.keys(eleTypes).map((type, index) => (
          <div className="settings-text-with-input" key={index}>
            {storyIndex === 0 && (
              <div className="settings-text">{eleTypes[type]}</div>
            )}

            <select
              className="settings-input"
              value={
                byStory
                  ? (selectedOptionByStory[type] &&
                      selectedOptionByStory[type][story]) ||
                    ""
                  : selectedOption[type]
              }
              disabled={!selectedOption[type]}
              onChange={(event) =>
                handleSelectChange(event, type, byStory ? story : null)
              }
            >
              <option
                className="title-scenario-options"
                value=""
                disabled={selectedOption[type]}
              >
                Select scenario
              </option>
              {Object.keys(rebarDataRef.current[type]).map(
                (scenario, scenarioIndex) => (
                  <option
                    className="scenario-options"
                    key={scenarioIndex}
                    value={scenario}
                  >
                    {scenario}
                  </option>
                )
              )}
            </select>
          </div>
        ))}
      </div>
    );
  };

  // Get story names if scenarioByStory is true
  const storyNames = scenarioByStory ? getStoryNames() : [];

  // On local state changes for scenario by story
  useEffect(() => {
    // If scenario by story has been just activated
    // It will store all the stories information with the original scenario
    // if there was already a configuration by story it will store the previous set up
    if (scenarioByStory) {
      // Aux object for storing updates
      const updatedSelectedOptionByStory = {};

      // For each story
      storyNames.forEach((story) => {
        // And for each element type
        Object.keys(eleTypes).forEach((type) => {
          // If some scenario was already selected then initialize key
          // if not, create key with undefined and do nothing
          if (!updatedSelectedOptionByStory[type]) {
            updatedSelectedOptionByStory[type] = !selectedScenarios[type]
              ? undefined
              : {};
          }

          // If no scenario was already selected continue
          if (!selectedScenarios[type]) return;

          // Checking if a previous configuration was applied
          if (
            scenariosByStory[type] &&
            scenariosByStory.hasOwnProperty(type) &&
            scenariosByStory[type].hasOwnProperty(story)
          ) {
            updatedSelectedOptionByStory[type][story] =
              scenariosByStory[type][story];

            // Store element type scenario for the story with the original scenario
            // If there was not a previous set up
          } else {
            updatedSelectedOptionByStory[type][story] = selectedOption[type];
          }
        });
      });

      // Update local state
      setSelectedOptionByStory(updatedSelectedOptionByStory);

      // If there will not be a combination of scenarios
    } else {
      // Change to undefined all the combinations at local state
      setSelectedOptionByStory({
        beams: undefined,
        joists: undefined,
        columns: undefined,
        walls: undefined,
      });

      // Iterate over eletype to restore general scenario
      Object.keys(eleTypes).forEach((type) => {
        // If there is not a selected scenario do nothing
        if (!selectedScenarios[type]) return;

        // Update global state again because this reducer updates dataToHandle
        dispatch(
          setScenario({ eleType: type, scenario: selectedScenarios[type] })
        );

        // If there is a visual report activated
        if (!rebarDataRef.current.toBeDisplayed.displayItem) return;

        // Update global state
        dispatch(
          dataToDisplay({
            displayItem: reinforcementData.toBeDisplayed.displayItem,
          })
        );
      });
    }
  }, [scenarioByStory]);

  // On changes of local state of scenario combination by story
  useEffect(() => {
    // Update global state
    dispatch(setData({ scenarioCombinationByStory: selectedOptionByStory }));
  }, [selectedOptionByStory]);

  return (
    <>
      <div className="settings-subheader">
        <div className="metrics-subtitle">Reinforcement scenario selection</div>
        <div className="settings-subtitle-icon">
          <TuneIcon
            color={scenarioByStory ? "primary" : "action"}
            onClick={() => {
              // If there is not any scenario selected do nothing
              if (!isSomeScenarioSelected) return;
              dispatch(setData({ combineScenariosByStory: !scenarioByStory }));
              setScenarioByStory(!scenarioByStory);
            }}
          />
        </div>
      </div>

      {!scenarioByStory
        ? generateSelects()
        : storyNames.map((story, index) => generateSelects(true, index, story))}
    </>
  );
};

export default SettingsScenarioSelection;
