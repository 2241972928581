import React, { useEffect } from "react";
import { useSelector } from "react-redux";

// Third party libraries
import * as echarts from "echarts";

// importing Slice functions for handling state
import { getData } from "../../slicers/reinforcement-data/reinforcementSlice";

// importing app's functionality
import {
  computeConstructionTime,
  computeConstructionEmissions,
  computeElementMaterialsPrice,
} from "../utilities";

const ScatterPlotByKey = ({
  elementType,
  title,
  yAxisTitle,
  domElementID,
  plotKey,
}) => {
  // Getting reinforcement data
  const reinforcementData = useSelector(getData);
  // Getting scenarios information
  const scenarios = reinforcementData[elementType];
  // Getting selected scenario for element type
  const selectedScenario = reinforcementData.selectedScenarios[elementType];

  // Getting prices parameters
  const prices = reinforcementData.materialPrices;
  const priceByDiameter = reinforcementData.priceByDiameter;
  const materialPricesByDiameter = reinforcementData.materialPricesByDiameter;
  // Getting speed and emissions parameters
  const speedParameters = reinforcementData.constructionSpeed;
  const emissionsParameters = reinforcementData.constructionEmissions;

  // Method for computing total material price
  const computeTotalPrice = (data) => {
    // Getting info by story
    const stories = Object.keys(data.by_story);
    let dataByElement = data["by_element"];

    // Init value where story price material will be accumulated
    let totalPrice = 0;

    // For each story
    stories.forEach((story) => {
      if (!dataByElement.hasOwnProperty(story)) return;
      const elements = dataByElement[story];
      // For each element
      for (const ele in elements) {
        if (!elements.hasOwnProperty(ele)) continue;
        // Rebar weight and concrete are cumulated
        const eleInfo = dataByElement[story][ele];
        totalPrice += computeElementMaterialsPrice(
          eleInfo,
          priceByDiameter,
          prices,
          materialPricesByDiameter
        );
      }
    });

    // Getting total material cost
    return totalPrice;
  };

  // Method for computing total construction time
  const computeTotalTime = (data) => {
    // The construction time depends on the complexity values of each element
    let constructionTime = 0;
    let dataByElement = data["by_element"];

    // For each story
    for (const story in dataByElement) {
      if (dataByElement.hasOwnProperty(story)) {
        const elements = dataByElement[story];
        // For each element
        for (const ele in elements) {
          if (elements.hasOwnProperty(ele)) {
            // Construction time is summed
            constructionTime += computeConstructionTime(
              elements[ele],
              speedParameters
            );
          }
        }
      }
    }

    // Getting total construction duration
    return constructionTime;
  };

  // Method for computing total construction emissions
  const computeTotalEmissions = (data) => {
    // The construction time depends on the complexity values of each element
    let constructionEmissions = 0;
    let dataByElement = data["by_element"];

    // For each story
    for (const story in dataByElement) {
      if (dataByElement.hasOwnProperty(story)) {
        const elements = dataByElement[story];
        // For each element
        for (const ele in elements) {
          if (elements.hasOwnProperty(ele)) {
            // Construction time is summed
            constructionEmissions += computeConstructionEmissions(
              elements[ele],
              emissionsParameters
            );
          }
        }
      }
    }

    // Getting total construction duration
    return constructionEmissions;
  };

  // Method for computing value to be plotted
  const computeValue = (data) => {
    // Init variable
    let value;

    // If rebar total weight must be computed
    if (plotKey === "rebar_weight") {
      value =
        (data.longitudinal_bars.total_bar_weight +
          data.stirrups.total_stirrups_weight) /
        1000;

      // If material price must be computed
    } else if (plotKey === "material_price") {
      value = computeTotalPrice(data);

      // If construction time must be computed
    } else if (plotKey === "construction_speed") {
      value = computeTotalTime(data) / 8;

      // If construction time must be computed
    } else if (plotKey === "emissions") {
      value = computeTotalEmissions(data);

      // Any other value just have to be read
    } else {
      value = data[plotKey];
    }

    return Math.round(value * 100) / 100;
  };

  // Extracted values by key
  const extractedValues = Object.entries(scenarios).map(([sce, data]) => ({
    key: sce,
    value: computeValue(data),
  }));

  // Getting value for selected scenario
  const selectedScenarioValue = [
    extractedValues.find((value) => value.key === selectedScenario) || {
      key: "No scenario selected",
      value: 0,
    },
  ];

  const valuesArray = extractedValues.map((item) => item.value);
  const minValue = Math.min(...valuesArray);
  const maxValue = Math.max(...valuesArray);

  const minMaxValues = extractedValues.filter(
    (item) => item.value === minValue || item.value === maxValue
  );

  // On mount
  useEffect(() => {
    // Getting DOM element
    const chartDom = document.getElementById(domElementID);

    // Initializing bar chart
    const chart = echarts.init(chartDom);

    const option = {
      title: {
        text: title,
        left: "center",
        textStyle: {
          fontSize: "1rem",
          fontWeight: "bold",
          color: "black",
          fontFamily:
            '"Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif',
        },
      },
      tooltip: {
        trigger: "item",
        formatter: (params) => {
          const [key, value] = params.data;
          return `Scenario: ${key}<br/>Value: ${value}`;
        },
        textStyle: {
          fontSize: "0.75rem",
          color: "black",
          fontFamily:
            '"Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif',
        },
      },
      xAxis: {
        type: "category",
        data: extractedValues.map((item) => item.key),
        axisLabel: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLine: {
          show: true,
        },
        name: "Reinforcement Scenarios",
        nameLocation: "middle",
        nameTextStyle: {
          color: "black",
          fontWeight: "bold",
          fontSize: "0.9rem",
          fontFamily:
            '"Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif',
        },
      },
      yAxis: {
        type: "value",
        min: Math.round(minValue * 0.9),
        name: yAxisTitle,
        nameLocation: "middle",
        nameTextStyle: {
          color: "black",
          fontWeight: "bold",
          fontSize: "0.9rem",
          fontFamily:
            '"Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif',
        },
        axisLabel: {
          color: "black",
          fontSize: "0.75rem",
          fontFamily:
            '"Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif',
        },
        nameGap: 50,
        axisLine: {
          show: true,
        },
        splitLine: {
          show: true,
        },
      },
      series: [
        {
          type: "effectScatter",
          symbolSize: 10,
          data: minMaxValues.map((item) => [item.key, item.value]),
          itemStyle: {
            color: "#85C1E9",
          },
        },
        {
          type: "effectScatter",
          symbolSize: 10,
          data: selectedScenarioValue.map((item) => [item.key, item.value]),
          itemStyle: {
            color: "#ec3434",
          },
        },
        {
          type: "scatter",
          data: extractedValues.map((item) => [item.key, item.value]),
          symbolSize: 7,
          itemStyle: {
            color: "black",
          },
        },
      ],
    };

    // Setting chart configuration
    chart.setOption(option);

    // On dismount cleaning chart
    return () => {
      chart.dispose();
    };
  }, [reinforcementData, elementType]);

  return <div id={domElementID}></div>;
};

export default ScatterPlotByKey;
