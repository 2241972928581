import { Vector3 } from "three";
import updateConnectivities from "./connectivities.js";

class Project {
  // Project version
  version = "1";
  // By default, element's type is beam (V)
  defaultType = "B";
  // An element could be of type: Joist(N), Beam(B), Column(C), Wall (W)
  supportedElements = ["N", "B", "C", "W"];
  // There are the following support types
  // Order matter, strict supports at the right
  supportsTypes = ["B", "C"];

  // Initialize data
  constructor(data) {
    // Structure's Initial information (from geometry file)
    const { axes, elements, info, layers, namedElements, nodes, seed } = data;

    this.info = info;
    this.axes = axes;
    this.elements = elements;
    this.namedElements = namedElements;
    this.stories = layers;
    this.nodes = nodes;

    // Updating element connectivities (initial and final nodes and unify formats from ProDes last GUI)
    this.updateElements(this.elements, this.namedElements, this.nodes);

    // Computing connectivities (groups of finite elements that together are a real element)
    this.connectivities = updateConnectivities(
      this.elements,
      this.supportedElements
    );

    // Moving to the origin all the coordinates and global axes
    this.extent = this.getExtent(this.nodes, this.axes);

    // Grouping nodes to make easier elements grouping (next step)
    this.agrupateData(
      this.nodes,
      // If nodes has the same x,y position
      (node, node_i) => node.x === node_i.x && node.y === node_i.y
    );

    // Grouping similar elements between stories to make easier its selection
    this.agrupateData(
      this.elements,
      (ele, ele_i) =>
        // if element's node has the same group
        this.nodes[ele.node_i].group === this.nodes[ele_i.node_i].group &&
        this.nodes[ele.node_f].group === this.nodes[ele_i.node_f].group
    );
    // Computing groups of elements
    this.extractGroupsOfElements();

    // Geometrically equal stories are grouped
    this.story_groups = this.agrupateData(this.stories, (story, story_i) => {
      const diafragmTypes = ["B", "J"];
      const elementsOnStory = this.elements.filter(
        (element) =>
          element.story == story.id && diafragmTypes.includes(element.type)
      );
      const elementsOnStory_i = this.elements.filter(
        (element) =>
          element.story == story_i.id && diafragmTypes.includes(element.type)
      );

      return (
        // True if both stories have the same amount of elements and all the
        // elements within each story has the same group
        elementsOnStory.length === elementsOnStory_i.length &&
        elementsOnStory.every((element) =>
          elementsOnStory_i.find(
            (element_i) => element.group === element_i.group
          )
        )
      );
    });
  }

  // Method for computing groups of elements
  extractGroupsOfElements() {
    this.elementGroups = {};

    this.elements.forEach((ele) => {
      const eleGroup = ele.group;

      if (!this.elementGroups[eleGroup]) {
        this.elementGroups[eleGroup] = [];
      }

      this.elementGroups[eleGroup].push(ele.id);
    });
  }

  // Groups the data data depending on the given validation function
  agrupateData(data, validation) {
    let groups = [];

    // For each data item
    data.forEach((item) => {
      // If the item is already grouped, continue
      if (item.group != null) return;
      // Filtering items that pass validation function
      const itemsAgroupated = data.filter((item_i) => validation(item, item_i));
      // New group's ID
      const group = groups.length;
      // Storing new group's items
      groups.push(itemsAgroupated.map((item) => item.id));
      // Storing group's ID in each item
      itemsAgroupated.forEach((item) => {
        item.group = `${group}`;
      });
    });

    return groups;
  }

  // Getting maximum coordinate in each direction
  getExtent(nodes, axes) {
    // Getting X, Y, Z array coordinates
    const x = nodes.map((node) => node.x);
    const y = nodes.map((node) => node.y);
    const z = nodes.map((node) => node.z);

    // X coordinates minimum and maximum values
    const xMin = Math.min(...x);
    const xMax = Math.max(...x);
    // Y coordinates minimum and maximum values
    const yMin = Math.min(...y);
    const yMax = Math.max(...y);
    // Z coordinates minimum and maximum values
    const zMin = Math.min(...z);
    const zMax = Math.max(...z);

    // Moving the entire structure to the minimum x, y and z coordinate
    nodes.forEach((node) => {
      node.x -= xMin;
      node.y -= yMin;
      node.z -= zMin;
    });
    // Moving global axes to minimum x and y
    axes.forEach((axe) => {
      axe.coor -= axe.direction === "X" ? xMin : yMin;
    });

    // Returning minimum and maximum values
    return {
      xMin: 0,
      xMax: parseFloat((xMax - xMin).toFixed(3)),
      yMin: 0,
      yMax: parseFloat((yMax - yMin).toFixed(3)),
      zMin: 0,
      zMax: parseFloat((zMax - zMin).toFixed(3)),
    };
  }

  // updates the fields of the dependent elements of the nodes
  // and unify information from ProDes last GUI
  updateElements(elements, namedElements, nodes) {
    // For each element
    elements.forEach((element) => {
      // Element's nodes
      let node_i = nodes[element.node_i];
      let node_f = nodes[element.node_f];

      // An element is inverted if
      if (
        // if it is vertical downwards
        (node_i.x === node_f.x && node_i.y > node_f.y) ||
        // if it is horizontal to the left
        (node_i.y === node_f.y && node_i.x > node_f.x) ||
        (node_f.x < node_i.x &&
          node_f.y > node_i.y &&
          node_i.x - node_f.x > node_f.y - node_i.y) ||
        // if the starting point is to the right
        (node_f.x < node_i.x && node_f.y < node_i.y) ||
        (node_f.x > node_i.x &&
          node_f.y < node_i.y &&
          node_i.y - node_f.y > node_f.x - node_i.x)
      ) {
        // Marking as inverted element
        element.inverted = true;
        // Swap nodes
        [element.node_i, element.node_f] = [element.node_f, element.node_i];
        [node_i, node_f] = [node_f, node_i];
      }

      // Element's direction
      // vectors for three rendering
      node_i = new Vector3(node_i.x, node_i.y, node_i.z);
      node_f = new Vector3(node_f.x, node_f.y, node_f.z);
      // direction and element length
      const direction = node_f.sub(node_i);
      element.length = element.length ? element.length : direction.length();
      element.length = parseFloat(element.length.toFixed(5));
      element.direction = direction.normalize().toArray();

      // Unifying ProDes last GUI formats
      element.right_lengthOffset = element.apoyo_der;
      element.left_lengthOffset = element.apoyo_izq;
      element.story = element.layer;
      element.modelStory = element.modelLayer;
      element.support_f = element.restrain_f;
      element.support_i = element.restrain_i;
      element.type = element.type === "V" ? "B" : element.type;

      // Deleting keys that are not longer necessary
      delete element.connectivity;
      delete element.inverted;
      delete element.invertedPhysics;
      delete element.layer;
      delete element.modelLayer;
      delete element.apoyo_der;
      delete element.apoyo_izq;
      delete element.restrain_f;
      delete element.restrain_i;
    });

    const newNamedElements = {};

    // Unifying ProDes last GUI formats
    namedElements.forEach((element) => {
      // Unifying formats
      element.type = element.type === "V" ? "B" : element.type;
      element.story = element.layer;

      // Storing element data at object
      newNamedElements[element.id] = element;

      // Deleting keys that are not longer necessary
      delete element.layer;
      delete element.id;
    });

    this.namedElements = newNamedElements;
  }

  // Serializing object to a dictionary
  serialize() {
    // Converting ids and story to strings
    this.elements.forEach((ele) => {
      ele.story = `${ele.story}`;
      ele.node_i = `${ele.node_i}`;
      ele.node_f = `${ele.node_f}`;
    });

    return {
      elements: this.elements,
      groupsOfElements: this.elementGroups,
      nodes: this.nodes,
      connectivities: this.connectivities,
      stories: this.stories,
      axes: this.axes,
      namedElements: this.namedElements,
      info: {
        version: this.version,
        program: this.info,
        units: this.units,
        supportsTypes: this.supportsTypes,
        supportedElements: this.supportedElements,
        defaultElement: this.defaultType,
        extent: this.extent,
      },
    };
  }
}

export default Project;
