// importing third party functions
import { createSlice } from "@reduxjs/toolkit";

const setHistoryAction = (state, actualState, record) => {
  /*
  This function stores a certain action in the history of the
  state.

  It stores the previous state for undo and reset the future states
  because if a new state is going to be stored there are not future
  actions.

  Also, it stores the history record that contains a string that indicates
  the type of the action ('INVERSION' for example) and an object that will be used
  for undo or redo actions in certain components of the application that are not 
  linked to the geometryStateObject (3D model for example)

  Parameters:
  ----------
  state (obj): state object before update
  actualState (obj): state that will update state object
  record (array): List of attributes to declare a record [type, Object for logic]

  Returns:
  --------
  Nothing
  */

  // Storing previous state for undo and reset future states
  state.past.push({ ...actualState });
  state.future = [];

  // Setting history record
  state.history.past.push(record);
  state.history.future = [];
};

export const geometrySlice = createSlice({
  // Project general slicer
  name: "projectGeometry",
  // By default the object start with empty children
  initialState: {
    // History of past states due to user actions
    past: [],
    // Present state
    present: {
      // Geometry object (from project)
      axes: [],
      connectivities: {},
      elements: [],
      groupsOfElements: {},
      info: {},
      namedElements: [],
      nodes: [],
      stories: [],
      floorGroups: [],
      crossSections: [],
    },
    // History of future states due to user undo actions
    future: [],
    // History of user actions that can be undone or redone
    // and that depends on a certain logic to be done the undo/redo (3D for example)
    history: {
      // Action to be execute (redo or undo)
      execute: null,
      // List of actions that can be undone
      past: [],
      // List of actions that can be redone
      future: [],
    },
  },
  // Reducer functions for controlling the state
  reducers: {
    // Update global geometry object
    setGeometry: (state, action) => {
      // Here each key to update (action) will replace the original
      // key of the state

      // Getting keys to update
      const keysToUpdate = Object.keys(action.payload);

      // for each key to update it is replaced the values of the state object
      keysToUpdate.forEach((key) => {
        state.present[key] = action.payload[key];
      });
    },
    // Logic for undo
    undoAction: (state, action) => {
      // General variables for undo
      const undoableState = action.payload;
      const nStates = undoableState.past.length;

      // If there are past geometry states
      if (nStates) {
        // storing future state as the actual state
        state.future.push({ ...undoableState.present });
        // setting the actual as state as the immediately previous state
        state.present = { ...undoableState.past[nStates - 1] };
        // deleting the last state because it is the present state now
        state.past.pop();

        // Setting history records
        state.history.execute = state.history.past[nStates - 1];
        state.history.future.push(state.history.past[nStates - 1]);
        state.history.past.pop();
      }
    },
    // Logic for redo
    redoAction: (state, action) => {
      // General variables for undo
      const redoableState = action.payload;
      const nStates = redoableState.future.length;

      // If there are future geometry states
      if (nStates) {
        // storing past state as the actual state
        state.past.push({ ...redoableState.present });
        // setting the actual as state as the immediately next state
        state.present = { ...redoableState.future[nStates - 1] };
        // deleting the next state because it is the present state now
        state.future.pop();

        // Setting history records
        state.history.execute = state.history.future[nStates - 1];
        state.history.past.push(state.history.future[nStates - 1]);
        state.history.future.pop();
      }
    },
    // Update Story name logic
    updateStoryName: (state, action) => {
      // Getting index and new name from action
      const index = action.payload.index;
      const newName = action.payload.name;

      // Getting the previous name
      const nameBefore = state.present.stories[index].name;
      //Updating new name
      state.present.stories[index].name = newName;

      // If there are floor Groups already created
      if (state.present.floorGroups.length) {
        // Assigning to a variable the floor groups
        const floorGroups = state.present.floorGroups;

        // For each groups it will be looking for the updated level to change its name
        floorGroups.forEach((group, index) => {
          // Getting levels in group
          let storeysIngroup = group.storeys;
          // Getting the position of the level if it is inside the group
          let posStory = storeysIngroup.indexOf(nameBefore);

          // Changing group name if it is the updated floor
          if (group.name === nameBefore) group.name = newName;

          // If the previous name was found, tha name is updated
          if (posStory != -1) {
            group.storeys[posStory] = newName;
            return;
          }
        });
      }
    }
  },
});

// Exporting the actions that will control the state
export const {
  setGeometry,
  undoAction,
  redoAction,
  updateStoryName,
} = geometrySlice.actions;

// Exporting function to get state
export const getGeometry = (state) => state.projectGeometry.present;
// Exporting reducer
export default geometrySlice.reducer;
