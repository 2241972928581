import React, { useState } from "react";
import { useSelector } from "react-redux";

// importing Slice functions for handling state
import { getData } from "../../slicers/reinforcement-data/reinforcementSlice";

// importing app's components
import ScatterPlotByKey from "./ScatterPlot";

// Names that will be shown at element type container
const eleTypes = {
  beams: "Beams",
  joists: "Joists",
  columns: "Columns",
  walls: "Walls",
};

// Information for plots
const plots = {
  material_cost: {
    title: "Total material price analysis",
    yAxisTitle: "Price ($ M)",
    domElementID: "costs-scatter-plot",
    plotKey: "material_price",
  },
  speed_construction: {
    title: "Construction speed analysis",
    yAxisTitle: "Construction time (days)",
    domElementID: "speed-scatter-plot",
    plotKey: "construction_speed",
  },
  rebar_weight: {
    title: "Reinforcement weight analysis",
    yAxisTitle: "Rebar weight (tonf)",
    domElementID: "weights-scatter-plot",
    plotKey: "rebar_weight",
  },
  bar_types: {
    title: "Number of bar types",
    yAxisTitle: "Bar types (units)",
    domElementID: "bar-types-scatter-plot",
    plotKey: "bar_types",
  },
  detailed_elements: {
    title: "Number of different detailed elements",
    yAxisTitle: "Detailed elements (units)",
    domElementID: "elements-scatter-plot",
    plotKey: "detailed_elements",
  },
  concrete: {
    title: "Concrete analysis",
    yAxisTitle: "Concrete volume (m³)",
    domElementID: "concrete-scatter-plot",
    plotKey: "concrete_volume",
  },
  co2_emissions: {
    title: "CO2 emissions analysis",
    yAxisTitle: "Emissions (m³)",
    domElementID: "emissions-scatter-plot",
    plotKey: "emissions",
  },
};

const ReinfAnalysis = () => {
  // Getting reinforcement data
  const reinforcementData = useSelector(getData);

  // Setting local state to handle reinforcement scenario selection
  const [selectedOption, setSelectedOption] = useState("beams");

  // Method for handling select change option for element type
  const handleSelectChange = (event) => {
    // Updating local state
    setSelectedOption(event.target.value);
  };

  return (
    <>
      <div className="subtitle-plus-select">
        {/* Rendering analysis of reinforcement data   */}
        <div className="analysis-subtitle">Element type:</div>

        {/* Element type selection selection */}
        <select
          className="eletype-selection"
          value={selectedOption}
          onChange={(event) => handleSelectChange(event)}
        >
          {/* First option is just a title */}
          <option className="title-eletype-options" value="">
            Select element type
          </option>

          {/* Element types with data */}
          {Object.keys(eleTypes).map((ele, index) => {
            return Object.keys(reinforcementData[ele]).length ? (
              <option className="eletype-options" key={index} value={ele}>
                {eleTypes[ele]}
              </option>
            ) : null;
          })}
        </select>
      </div>

      {/* Rendering scatter plots */}
      <div className="scatter-plots">
        {Object.entries(plots).map(([key, plotInfo]) => (
          <div key={key} id={plotInfo.domElementID}>
            <ScatterPlotByKey
              elementType={selectedOption}
              title={plotInfo.title}
              yAxisTitle={plotInfo.yAxisTitle}
              domElementID={plotInfo.domElementID}
              plotKey={plotInfo.plotKey}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default ReinfAnalysis;
