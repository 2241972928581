import React from "react";

// App's components
import SettingsScenarioSelection from "./SettingsScenarioSelection";
import SettingsMaterialPrice from "./SettingsMaterialPrice";
import SettingsLaborHours from "./SettingsLaborHours";
import SettingsEmissions from "./SettingsEmissions";

const InputParametersForm = () => {
  return (
    <div className="settings-box">
      {/* Reinforcement scenarios that were selected previously */}
      <SettingsScenarioSelection />

      {/* Material prices */}
      <SettingsMaterialPrice />

      {/* Labor hours */}
      <SettingsLaborHours />

      {/* Construction CO2 emissions */}
      <SettingsEmissions />
    </div>
  );
};

export default InputParametersForm;
