import React from "react";
import { useSelector, useDispatch } from "react-redux";

// Third party components
import TuneIcon from "@mui/icons-material/Tune";

// importing Slice functions for handling state
import {
  getData,
  setEmissionsParameters,
} from "../../slicers/reinforcement-data/reinforcementSlice";

const emissionsItems = {
  emissionsByKg: (
    <>
      CO<sub>2</sub> emissions per kg
    </>
  ),
  emissionsByM3: (
    <>
      CO<sub>2</sub> emissions per m³
    </>
  ),
};

const SettingsEmissions = () => {
  // Setting dispatch function
  const dispatch = useDispatch();

  // Getting reinforcement data
  const reinforcementData = useSelector(getData);

  // Getting emissions parameters
  const emissionsParameters = reinforcementData.constructionEmissions;

  // Method for handling emissions parameters changes
  const handleEmissionsChange = (item, value) => {
    const checkedValueFormat = parseFloat(value);
    if (!isNaN(checkedValueFormat)) {
      dispatch(
        setEmissionsParameters({
          itemToUpdate: item,
          value: checkedValueFormat,
        })
      );
    }
  };

  return (
    <>
      {/* Labor hours */}
      <div className="settings-subheader">
        <div className="metrics-subtitle">
          <>
            CO<sub>2</sub> emissions parameters (average)
          </>
        </div>
        <div className="settings-subtitle-icon">
          <TuneIcon />
        </div>
      </div>

      {/* Speed items */}
      <div className="settings-inputs">
        {Object.keys(emissionsItems).map((item, index) => (
          <div className="settings-text-with-input" key={index}>
            {/* Element price name */}
            <div className="settings-text">{emissionsItems[item]}</div>
            {/* Price value */}
            <input
              className="settings-input"
              type="number"
              value={emissionsParameters[item]}
              onChange={(e) => handleEmissionsChange(item, e.target.value)}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default SettingsEmissions;
