import { Vector3 } from 'three';

// Updating elements connectivities
function updateConnectivities(elements, supportedElements) {
  let connec = [];

  // For each element type of the project
  supportedElements.forEach((elementType) => {
    // New array of arrays in which it is filtered the elements by type
    let connectivities = elements
      .filter((element) => element.type === elementType)
      // First each element is a unique connectivity
      .map((element) => [element.id]);

    // counter of elements to exit the following while loop
    let i = 0;
    while (i < connectivities.length) {
      // First element of i-connectivity
      const head = elements.find((ele) => ele.id === connectivities[i][0]);

      // Getting the element that could be joined to the head element
      const tail_index = connectivities.findIndex((connec) => {
        // For each connectivity array
        // Getting the last element in the array
        const tail = elements.find(
          (ele) => ele.id === connec[connec.length - 1]
        );
        
        // if i-connectivity is already named
        if (head.named) {
          // Is connectivity if
          return (
            // tail is also named and
            tail.named &&
            // it is the same name and 
            head.named === tail.named &&
            // and head's index is consecutive to tail's index
            head.connectivityIndex === tail.connectivityIndex + 1
          );

          // If either head and tail are not already named
        } else if (!tail.named) {
          // If they don't share nodes, returns false  
          if (head.node_i !== tail.node_f) {
            return false;
          }
          // Checking directions of each finite element
          const dir_head = new Vector3(...head.direction);
          const dir_tail = new Vector3(...tail.direction);
          // Angle between elements
          const angle_between = dir_head.angleTo(dir_tail);
          // There is a connectivity only if the angle between
          // finite elements is less than 30°
          return Math.abs(angle_between) < Math.PI / 6;
        }
      });

      // If a joinable element was found
      if (tail_index !== -1) {
        // Updating i-connectivity array
        connectivities[i] = [
          ...connectivities[tail_index],
          ...connectivities[i],
        ];
        // Deleting connectivity array of the tail element
        connectivities.splice(tail_index, 1);
        i -= 1;
      }

      // Next connectivity array
      i += 1;
    }

    // Storing connectivities
    connec = [...connec, ...connectivities];
  });

  // Creating new object to serialize the connectivities array
  const connecObject = {}

  // For each updated connectivity
  connec.forEach((connectivity, index) => {
    // For each connectivity's element
    connectivity.forEach((element, position) => {
      // Getting the element
      const connecElement = elements.find((ele) => ele.id == element);
      // Assigning index of the connectivity
      connecElement.connectivityIndex = index;
      // If the project has any mutations there will be a key
      // for returning to the original connectivity
      connecElement.defaultConnectivityIndex = index;
    });

    // Serialize the object
    connecObject[index] = connectivity
  });

  return connecObject;
}

export default updateConnectivities