import { configureStore } from "@reduxjs/toolkit";

// Slicers import
import layoutSlice from "../slicers/layout/layoutSlice";
import geometrySlice from "../slicers/project-geometry/geometrySlice";
import buildingSlice from "../slicers/3D-state/buildingSlice";
import reinforcementSlice from "../slicers/reinforcement-data/reinforcementSlice";

// Project store
const projectStore = configureStore({
  reducer: {
    // Reducer for handling  the global screen size and its main components
    // header, footer and body
    layout: layoutSlice,
    // Reducer for handling the project's geometry object
    projectGeometry: geometrySlice,
    // Reducer for handling the project's building 3D model
    buildingState: buildingSlice,
    // Reducer for reinforcement data
    reinforcementData: reinforcementSlice,
  },
});

export default projectStore;
