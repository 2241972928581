// Root imports
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// importing Slice functions for handling state
import { getGeometry } from "../../../slicers/project-geometry/geometrySlice";
import {
  getBuildingState,
  setBuildingState,
} from "../../../slicers/3D-state/buildingSlice";

// Third party functions and components
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Tooltip from "@mui/material/Tooltip";

// Importing ProDes app components
import SingleGroup from "./SingleGroup";

const GeneralGroups = () => {
  // Hook for connecting store
  const dispatch = useDispatch();
  // Getting geometry state
  const geoState = useSelector(getGeometry);
  // Getting storeys information
  const stories = geoState.stories;
  // Getting floor groups from global geometry state
  const floorGroups = geoState.floorGroups;
  // Getting visiblefloors from building states
  const visibleFloors = useSelector(getBuildingState).visibleFloors;
  // Setting local state to handle changes
  const [visiFloors, setVisiFloors] = useState(visibleFloors);

  // Function for getting the story id of each grouped story
  const storeysInGroups = () => {
    // Array where grouped storeys will be stored
    const groupedStoreysIds = [];
    // For each existing group
    floorGroups.forEach((group) => {
      // Getting the ids of the grouped storeys
      const storeysIds = stories
        .filter((story) => group.storeys.includes(story.name))
        .map((story) => parseInt(story.id));

      groupedStoreysIds.push(...storeysIds);
    });

    return groupedStoreysIds;
  };

  // Getting the story id of each grouped story
  const groupedStoreys = storeysInGroups();
  // Checking if all grouped storeys are visible
  const allGroupedAreVisible = groupedStoreys.every((groupedStory) =>
    visiFloors.includes(groupedStory)
  );

  // Method for handling general visualization of stories
  const turnOnOffGroups = (event, action) => {
    // So the event wont propagate beyond this component
    event.stopPropagation();

    let newVisibleFloors;
    // Updating building state with visible story if it is not visible yet
    if (action === "On") {
      // Copying visible floors
      newVisibleFloors = [...visiFloors];
      // Complementing the list of visible floors with those of the group
      groupedStoreys.forEach((groupedStory) => {
        if (!newVisibleFloors.includes(groupedStory)) {
          newVisibleFloors.push(groupedStory);
        }
      });
      // Updating building state without the storeys if it was visible
    } else if (action === "Off") {
      // Creating array with newVisible floors
      newVisibleFloors = visiFloors.filter(
        (floor) => !groupedStoreys.includes(floor)
      );
    }
    // Updating state
    dispatch(setBuildingState({ visibleFloors: newVisibleFloors }));
  };

  // On changes of story visibility
  useEffect(() => {
    // Updating local state
    setVisiFloors(visibleFloors);
  }, [visibleFloors]);

  return (
    // General container for group names and visualization options
    <div>
      {/* General component with group names and options */}
      <Accordion>
        {/* General title */}
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          {/* General Visibility Icon */}
          <div className="accordion-titlebar">
            <div className="accordion-title">Story Groups</div>
            <div className="accordion-title-icon">
              {/* If all grouped storeys are visible */}
              {allGroupedAreVisible && groupedStoreys.length ? (
                <Tooltip title="Hide all story groups">
                  <VisibilityIcon
                    color="action"
                    fontSize="small"
                    onClick={(event) => turnOnOffGroups(event, "Off")}
                  />
                </Tooltip>
              ) : (
                <Tooltip title="Show all story groups">
                  <VisibilityOffIcon
                    color="action"
                    fontSize="small"
                    onClick={(event) => turnOnOffGroups(event, "On")}
                  />
                </Tooltip>
              )}
            </div>
          </div>
        </AccordionSummary>

        {/* General visibility controls (activate/deactivate all groups) */}
        <AccordionDetails>
          <div>
            {floorGroups.map((group, index) => (
              <div key={index}>
                <SingleGroup group={group} />
              </div>
            ))}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default GeneralGroups;
