import React from "react";
import { useSelector, useDispatch } from "react-redux";

// Third party components
import TuneIcon from "@mui/icons-material/Tune";

// importing Slice functions for handling state
import {
  getData,
  setSpeedParameters,
} from "../../slicers/reinforcement-data/reinforcementSlice";

const speedItems = {
  crewSize: "Crew size",
  manHoursByKg: "Steel labor hours per kg",
  manHoursByM3: "Concrete labor hours per m³",
  manHoursByM2: "Formwork labor hours per m²",
};

const SettingsLaborHours = () => {
  // Setting dispatch function
  const dispatch = useDispatch();

  // Getting reinforcement data
  const reinforcementData = useSelector(getData);

  // Getting speed parameters
  const speedParameters = reinforcementData.constructionSpeed;

  // Method for handling speed parameters changes
  const handleSpeedChange = (item, value) => {
    const checkedValueFormat = parseFloat(value);
    if (!isNaN(checkedValueFormat)) {
      dispatch(
        setSpeedParameters({ itemToUpdate: item, value: checkedValueFormat })
      );
    }
  };

  return (
    <>
      {/* Labor hours */}
      <div className="settings-subheader">
        <div className="metrics-subtitle">Speed parameters (average)</div>
        <div className="settings-subtitle-icon">
          <TuneIcon />
        </div>
      </div>

      {/* Speed items */}
      <div className="settings-inputs">
        {Object.keys(speedItems).map((item, index) => (
          <div className="settings-text-with-input" key={index}>
            {/* Element price name */}
            <div className="settings-text">{speedItems[item]}</div>
            {/* Price value */}
            <input
              className="settings-input"
              type="number"
              value={speedParameters[item]}
              onChange={(e) => handleSpeedChange(item, e.target.value)}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default SettingsLaborHours;
