// importing third party functions
import { createSlice } from "@reduxjs/toolkit";

export const layoutSlice = createSlice({
  // Layout Slicer structure
  name: "layout",
  // By default the screen sizes are 0
  initialState: {
    sizes: {
      headerHeight: 0,
      windowHeight: 0,
      contentHeight: 0,
      contentWidth: 0,
      sidebarWidth: 0,
    },
  },
  // Reducer functions for controlling the state
  reducers: {
    // Update screen sizes
    update: (state, action) => {
      // Here each key to update (action) will replace the original
      // key of the state

      // Getting keys to update
      const keysToUpdate = Object.keys(action.payload);

      // for each key to update it is replaced the values of the state object
      keysToUpdate.forEach((key) => {
        state.sizes[key] = action.payload[key];
      });
    },
  },
});

// Exporting the actions that will control the state
export const { update } = layoutSlice.actions;
// Exporting function to get state
export const getSizes = (state) => state.layout.sizes;
// Exporting reducer
export default layoutSlice.reducer;
