import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// Third party components
import TuneIcon from "@mui/icons-material/Tune";

// importing Slice functions for handling state
import {
  getData,
  setData,
  setPrices,
} from "../../slicers/reinforcement-data/reinforcementSlice";

const priceItems = {
  concrete: "Concrete ($/m³)",
  rebar: "Rebar  ($/kg)",
  connectors: "Connectors ($/un)",
  heads: "Mech. Heads ($/un)",
};

const SettingsMaterialPrice = () => {
  // Setting dispatch function
  const dispatch = useDispatch();

  // Getting reinforcement data
  const reinforcementData = useSelector(getData);
  // Getting scenarios information
  const selectedScenarios = reinforcementData.selectedScenarios;
  // Getting material prices
  const prices = reinforcementData.materialPrices;
  // Getting material prices by diameter
  const rebarPricesByDiameter = reinforcementData.materialPricesByDiameter;

  // Checking if some scenario was selected
  const isSomeScenarioSelected = Object.values(selectedScenarios).some(
    (sce) => sce !== undefined
  );

  // Local state to handle changes between general prices or by diameter
  const [pricesByDiameter, setPricesByDiameter] = useState(
    reinforcementData.priceByDiameter
  );

  // Local state to handle rebar prices by diameter
  const [rebarPrices, setRebarPrices] = useState(
    reinforcementData.materialPricesByDiameter
  );

  // Method for handling price changes
  const handlePriceChange = (priceItem, value, type, diameter) => {
    // Checking value to be a number
    const checkedValueFormat = parseFloat(value);

    // If it is not a number, do nothing
    if (isNaN(checkedValueFormat)) return;

    // If it is not a value for an specific diameter
    if (type === "general") {
      dispatch(
        setPrices({ itemToUpdate: priceItem, price: checkedValueFormat })
      );
    }

    // Updating local state for rebar price by diameter
    if (type === "by_diameter") {
      // Just local because each time that local state changes, global is updated by effect
      setRebarPrices((prevRebarPrices) => ({
        ...prevRebarPrices,
        [priceItem]: {
          ...prevRebarPrices[priceItem],
          [diameter]: checkedValueFormat,
        },
      }));
    }
  };

  const getDiameters = (keysForExtraction) => {
    // Custom comparison function to sorting diameters array
    const compareDiameters = (a, b) => {
      // Function to parse diameter values
      const parseDiameter = (str) => {
        // Remove non-numeric characters and convert fraction to a number if possible
        // Remove all non-numeric, non-fraction characters
        const cleaned = str.replace(/[^0-9\/.]/g, "");
        const parts = cleaned.split("/");
        if (parts.length === 2) {
          return parseFloat(parts[0]) / parseFloat(parts[1]);
        }
        return parseFloat(cleaned);
      };

      // Parse the diameters
      const aValue = parseDiameter(a);
      const bValue = parseDiameter(b);

      // If both values are numbers, compare them
      if (!isNaN(aValue) && !isNaN(bValue)) {
        return aValue - bValue;
      }
      // If they cannot be compared as numbers, maintain the original order
      return 0;
    };

    // Initialize variables
    let diameters = [];

    // By element it will be pushing into diameters all by diameter information
    for (let eleType of Object.keys(selectedScenarios)) {
      // Getting scenarios from reinforcement data
      const scenariosByElement = reinforcementData[eleType];

      // For each scenario
      Object.values(scenariosByElement).forEach((scenario) => {
        // Pushing into diameters each key from object that will be used in extraction
        keysForExtraction.forEach((key) => {
          if (key === "by_diameter") {
            diameters.push(scenario.stirrups[key]);
          } else {
            diameters.push(scenario.longitudinal_bars[key]);
          }
        });
      });
    }

    // Getting diameters that were used in all reinforcement scenarios
    diameters = diameters.flatMap((obj) =>
      Object.entries(obj)
        .filter(([key, value]) => value.n > 0)
        .map(([key]) => key)
    );

    // Getting unique values
    diameters = [...new Set(diameters)];
    // Sorting diameters array
    diameters = diameters.slice().sort(compareDiameters);

    return diameters;
  };

  // Get diameters for each reinforcement price parameter
  const diameters = {
    rebar: getDiameters(["bars_by_diameter", "by_diameter"]),
    connectors: getDiameters(["connectors_by_diameter"]),
    heads: getDiameters(["heads_by_diameter"]),
  };

  // Method for generate input by diameter
  const genInputsByDiameter = (item, itemID) => {
    return (
      // List header (Phi + text)
      <div className="settings-price-item" key={itemID}>
        <div className="settings-diameter-input">
          <div className="settings-diameter">Φ</div>
          <div className="settings-text settings-price-text">
            {priceItems[item]}
          </div>
        </div>

        {/* For each diameter (diameter + input) */}
        {diameters[item].map((diameter, diameterIndex) => (
          <div className="settings-diameter-input" key={diameterIndex}>
            <div className="settings-diameter diameter-text">{diameter}</div>
            {/* Price value */}
            <input
              className="settings-price-input"
              type="number"
              value={
                pricesByDiameter
                  ? (rebarPrices[item] && rebarPrices[item][diameter]) || ""
                  : prices[item]
              }
              onChange={(e) =>
                handlePriceChange(item, e.target.value, "by_diameter", diameter)
              }
            />
          </div>
        ))}
      </div>
    );
  };

  // Method for generate general input
  const genGeneralInput = (item, itemID) => {
    return (
      // List header (Just the text)
      <div className="settings-price-item" key={itemID}>
        <div className="settings-diameter-input">
          <div className="settings-text">{priceItems[item]}</div>
        </div>
        {/* Price value */}
        <input
          className="settings-input"
          type="number"
          value={prices[item]}
          onChange={(e) => handlePriceChange(item, e.target.value, "general")}
        />
      </div>
    );
  };

  // On local state changes for prices by diameter
  useEffect(() => {
    // If prices by dia,eter has been just activated
    // It will store all the prices information with the general price
    // if there was already a configuration by diameter it will store the previous set up
    if (pricesByDiameter) {
      // Aux object for storing updates
      const updatedSelectedOptionByDiameter = {};

      // For each story
      Object.keys(priceItems).forEach((item, index) => {
        // For concrete (first item), do nothing
        if (!index) return;
        // And for each element type

        diameters[item].forEach((diam) => {
          // If some scenario was already selected then initialize key
          // if not, create key with undefined and do nothing
          if (!updatedSelectedOptionByDiameter[item]) {
            updatedSelectedOptionByDiameter[item] = {};
          }

          // Checking if a previous configuration was applied
          if (
            rebarPricesByDiameter[item] &&
            rebarPricesByDiameter.hasOwnProperty(item) &&
            rebarPricesByDiameter[item].hasOwnProperty(diam)
          ) {
            updatedSelectedOptionByDiameter[item][diam] =
              rebarPricesByDiameter[item][diam];

            // Store the general price if there was not a previous set up
          } else {
            updatedSelectedOptionByDiameter[item][diam] = prices[item];
          }
        });
      });

      // Update local state
      setRebarPrices(updatedSelectedOptionByDiameter);

      // If there will not be a combination of scenarios
    } else {
      // Change to undefined all the combinations at local state
      setRebarPrices({
        rebar: undefined,
        connectors: undefined,
        heads: undefined,
      });
    }
  }, [pricesByDiameter]);

  // On changes of local state of prices by diameter
  useEffect(() => {
    // Update global state
    dispatch(setData({ materialPricesByDiameter: rebarPrices }));
  }, [rebarPrices]);

  return (
    <>
      {/* Price for materials */}
      <div className="settings-subheader">
        <div className="metrics-subtitle">Materials prices (average)</div>
        <div className="settings-subtitle-icon">
          <TuneIcon
            color={pricesByDiameter ? "primary" : "action"}
            onClick={() => {
              // If there is not any scenario selected do nothing
              if (!isSomeScenarioSelected) return;
              dispatch(setData({ priceByDiameter: !pricesByDiameter }));
              setPricesByDiameter(!pricesByDiameter);
            }}
          />
        </div>
      </div>

      <div className="settings-inputs">
        {Object.keys(priceItems).map((item, itemID) => {
          if (itemID && !diameters[item].length) return;

          if (!pricesByDiameter || !itemID) {
            return genGeneralInput(item, itemID);
          }

          return genInputsByDiameter(item, itemID);
        })}
      </div>
    </>
  );
};

export default SettingsMaterialPrice;
