// Getting data as arrays for the chart
export function prepareDataForChart(
  reinforcementData,
  chartData,
  chartKey,
  eleTypes,
  elementType,
  scenario
) {
  // If there is not scenario selected do nothing
  if (!scenario) {
    return {};
  }

  // Getting info by story
  const stories = Object.keys(reinforcementData[elementType].by_story);
  const dataByStory = reinforcementData[elementType].by_story;

  // Storing concrete volume data
  if (chartKey === "concrete_volume") {
    // Initialize value to store
    let valueToStore = 0;
    // Accumulate value by story
    stories.forEach((story) => {
      valueToStore += dataByStory[story][chartKey];
    });

    // As the key exist at root object it just have to be read
    chartData.push({
      value: Math.round(valueToStore * 10) / 10,
      name: eleTypes[elementType],
    });

    // Storing data by diameter when charKey value is not at root object
  } else {
    stories.forEach((story) => {
      // Getting by diameter data of longitudinal reinforcement
      const rebar_by_diameter =
        dataByStory[story].longitudinal_bars.bars_by_diameter;
      // Getting by diameter data of stirrups
      const stirrups_by_diameter = dataByStory[story].stirrups.by_diameter;

      // Storing by diameter objects at chartData. ChartData will be combined next
      [rebar_by_diameter, stirrups_by_diameter].forEach((type) => {
        chartData.push(
          Object.entries(type).reduce((acc, [key, value]) => {
            acc[key] = Math.round((value.w * 100) / 1000) / 100;
            return acc;
          }, {})
        );
      });
    });
  }
}

// Getting data as arrays by diameter
export function getDataByDiameter(
  reinforcementData,
  dataByDiameter,
  elementType,
  scenario,
  extractDataFor
) {
  // If the scenario exists
  if (!scenario) {
    return {};
  }

  if (extractDataFor === "compute_rebar_prices") {
    // Getting by diameter data of longitudinal reinforcement
    const rebar_by_diameter =
      reinforcementData[elementType][scenario].longitudinal_bars
        .bars_by_diameter;
    // Getting by diameter data of stirrups
    const stirrups_by_diameter =
      reinforcementData[elementType][scenario].stirrups.by_diameter;

    // Storing by diameter objects at data. Data will be combined next
    [rebar_by_diameter, stirrups_by_diameter].forEach((type) => {
      dataByDiameter.push(
        Object.entries(type).reduce((acc, [key, value]) => {
          // Storing rebar weight "w"
          acc[key] = Math.round(value.w);
          return acc;
        }, {})
      );
    });
  } else if (extractDataFor === "compute_connectors_prices") {
    // Getting by diameter data of connectors
    const connector_by_diameter =
      reinforcementData[elementType][scenario].longitudinal_bars
        .connectors_by_diameter;

    // Storing by diameter objects at data. Data will be combined next
    dataByDiameter.push(
      Object.entries(connector_by_diameter).reduce((acc, [key, value]) => {
        // Storing number of connectors "n"
        acc[key] = Math.round(value.n);
        return acc;
      }, {})
    );
  }
}

// Method for combining data that comes from by diameter objects
export function combineValues(dataByDiameter) {
  // First will be stored the sum of values by key
  const combinedValues = dataByDiameter.reduce((acc, obj) => {
    Object.entries(obj).forEach(([key, value]) => {
      if (!acc[key]) {
        acc[key] = 0;
      }
      acc[key] += value;
    });
    return acc;
  }, {});

  // Transforming array to the needed format for the chart
  const resultArray = Object.entries(combinedValues).map(([key, value]) => ({
    name: key,
    value: value,
  }));

  return resultArray;
}

// Method for transforming values to percentage
export function convertValuesToPercentage(data) {
  // Getting total
  const total = data.reduce((acc, obj) => acc + obj.value, 0);

  // Computing each percentage
  const newData = data.map((obj) => ({
    ...obj,
    value: total !== 0 ? Math.round((obj.value / total) * 100 * 10) / 10 : 0,
  }));

  return newData;
}

// Function to interpolate colors from color scale
export function interpolateColor(minValue, maxValue, value) {
  // Colors data base
  const colors = [
    { r: 0, g: 0, b: 255 }, // Blue
    { r: 0, g: 255, b: 255 }, // Light blue
    { r: 0, g: 255, b: 0 }, // Green
    { r: 255, g: 255, b: 0 }, // Yellow
    { r: 255, g: 165, b: 0 }, // Orange
    { r: 255, g: 0, b: 0 }, // Red
  ];

  // Computing interpolated colors
  const numColors = colors.length;
  const ratio = (value - minValue) / (maxValue - minValue);
  const scaledValue = ratio * (numColors - 1);
  const idx1 = Math.floor(scaledValue);
  const idx2 = Math.min(idx1 + 1, numColors - 1);
  const t = scaledValue - idx1;

  const color1 = colors[idx1];
  const color2 = colors[idx2];

  const r = Math.round(color1.r + t * (color2.r - color1.r));
  const g = Math.round(color1.g + t * (color2.g - color1.g));
  const b = Math.round(color1.b + t * (color2.b - color1.b));

  // Transforming RGB to HEX
  const toHex = (component) => {
    const hex = component.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  };

  // Returning hex color
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}

// Function to compute element construction time
export function computeConstructionTime(elementData, speedParameters) {
  // Rebar labor hours of rebar
  const rebar =
    elementData.total_rebar_weight *
    (speedParameters.manHoursByKg + elementData.labor_hours_modifier / 1000);
  // Rebar labor hours of concrete
  const concrete = elementData.vol_concreto * speedParameters.manHoursByM3;
  // Rebar labor hours of formwork
  const formwork =
    (elementData.vol_concreto * speedParameters.manHoursByM2) / 4;

  return (
    Math.round(
      ((rebar + concrete + formwork) / speedParameters.crewSize) * 10
    ) / 10
  );
}

// Function to compute element construction emissions
export function computeConstructionEmissions(elementData, emissionsParameters) {
  return (
    Math.round(
      ((elementData.total_rebar_weight * emissionsParameters.emissionsByKg) /
        1000 +
        (elementData.vol_concreto * emissionsParameters.emissionsByM3) / 1000) *
        10
    ) / 10
  );
}

// Function to compute a single element materials price
export function computeElementMaterialsPrice(
  elementData,
  priceByDiameter,
  generalPrices,
  materialPricesByDiameter
) {
  // Initialize value for material cost
  let materialCost;

  // Function to accumulate by key
  const accumByKey = (value, dataByDiameter, key, type) => {
    // For each diameter
    Object.keys(dataByDiameter).forEach((diameter) => {
      // If price exists means that rebar exists
      if (!materialPricesByDiameter[key]) return;
      if (!materialPricesByDiameter[key][diameter]) return;
      // Accumulate value
      value +=
        dataByDiameter[diameter][type] *
        materialPricesByDiameter[key][diameter];
    });

    return value;
  };

  // Concrete volume is global
  const concreteVolume = elementData["vol_concreto"];

  // If the calculation is going to be general, not by diameter
  if (!priceByDiameter) {
    // Getting total rebar weight
    const rebarWeight =
      elementData.longitudinal_bars_weight + elementData.stirrups_weight;

    // Getting the total of connectors
    const connectors = elementData.connectors_total;

    // Getting the total of heads
    const heads = elementData.heads_total;

    // Computing cost of rebar + connectors + heads
    materialCost =
      rebarWeight * generalPrices.rebar +
      connectors * generalPrices.connectors +
      heads * generalPrices.heads;

    // If the calculation is going to be by diameter
  } else {
    // Initialize values for accumulation
    let rebarPrice = 0;
    let connectorsPrice = 0;
    let headsPrice = 0;

    // Reading from elementData by diameter data
    const barsByDiam = elementData.bars_by_diameter;
    const stirrupsByDiam = elementData.stirrups_by_diameter;
    const connectByDiam = elementData.connectors_by_diameter;
    const headsByDiam = elementData.heads_by_diameter;

    // Getting prices for each key
    rebarPrice = accumByKey(rebarPrice, barsByDiam, "rebar", "w");
    rebarPrice = accumByKey(rebarPrice, stirrupsByDiam, "rebar", "w");
    connectorsPrice = accumByKey(
      connectorsPrice,
      connectByDiam,
      "connectors",
      "n"
    );
    headsPrice = accumByKey(headsPrice, headsByDiam, "heads", "n");

    // Computing cost of rebar + connectors + heads
    materialCost = rebarPrice + connectorsPrice + headsPrice;
  }

  // Adding concrete's price
  materialCost += concreteVolume * generalPrices.concrete;

  // Returns price in millions
  return Math.round((materialCost * 100) / 1000000) / 100;
}
