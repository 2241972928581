import React, { memo } from "react";
import { useSelector } from "react-redux";

// ProDes css files, components and functionality
import GeneralStoreys from "./components/GeneralStoreys";
import GeneralGroups from "./components/GeneralGroups";
import ElementTypes from "./components/GeneralElementTypes";
import ElementsData from "./components/GeneralElementData";
import DisplayOptions from "./components/GenerateVisualReports";

// importing Slice functions for handling state
import { getGeometry } from "../../slicers/project-geometry/geometrySlice";

// ProDes style sheets
import "../css/content.css";

// This component renders the sidebar with the general data
// of a project, it will only be shown when no element has been selected
const ProjectGeneralData = memo(() => {
  // Getting floor groups from global geometry state
  const floorGroups = useSelector(getGeometry).floorGroups;

  // Rendering the component
  return (
    <div className="sidebar-container">
      {/* Sidebar title */}
      <div className="sidebar-title"> PROJECT VISIBILITY OPTIONS</div>

      {/* Naming storeys and floor visualization */}
      <div className="sidebar-component">
        <GeneralStoreys />
      </div>

      {/* Just a horizontal line */}
      <hr />

      {/* Visualization options for user's storeys groups if there is any story group */}
      {floorGroups.length ? (
        <div>
          <div className="sidebar-component">
            <GeneralGroups />
          </div>
          {/* Just a horizontal line */}
          <hr />
        </div>
      ) : null}

      {/* Types of elements visualization options */}
      <div className="sidebar-component">
        <ElementTypes />
      </div>

      {/* Just a horizontal line */}
      <hr />

      {/* Sidebar reinforcement title */}
      <div className="sidebar-reinf-data"> REINFORCEMENT ANALYSIS DATA</div>

      {/* Naming storeys and floor visualization */}
      <div className="sidebar-component">
        <ElementsData />
      </div>

      {/* Sidebar reinforcement title */}
      <div className="sidebar-reinf-data"> DATA VISUALIZATION</div>

      {/* Visualization options */}
      <div className="sidebar-component">
        <DisplayOptions />
      </div>
    </div>
  );
});

export default ProjectGeneralData;
