import React from "react";
import { useSelector } from "react-redux";

// importing Slice functions for handling state
import { getData } from "../../slicers/reinforcement-data/reinforcementSlice";

// importing app's components
import StoryDistributionChart from "./StoryDistributionChart";
import PieChartByKey from "./PieChart";

// Data that will be displayed at data totals div
const Materials = () => {
  // Getting reinforcement data
  const reinforcementData = useSelector(getData);
  // Getting data from where info is going to be extracted
  const dataToBeHandled = reinforcementData.dataToBeHandled;
  // Getting selected scenarios information
  const selectedScenarios = reinforcementData.selectedScenarios;

  // Initialize totals
  const dataTotals = {
    reinforcement_total_weight: { value: 0, text: "Rebar total (tonf)" },
    concrete_volume: { value: 0, text: "Concrete volume (m³)" },
    detailed_elements: { value: 0, text: "Detailed elements" },
  };

  // Computing totals
  Object.entries(selectedScenarios).forEach(([eletype, scenario]) => {
    // If there is not scenario do nothing
    if (!scenario) return;

    // As scenario by element can be modified total must be computed by element
    let totalRebar = 0;
    let totalConcrete = 0;
    let dataByElement = dataToBeHandled[eletype]["by_element"];

    // For each story
    for (const story in dataByElement) {
      if (!dataByElement.hasOwnProperty(story)) continue;
      const elements = dataByElement[story];
      // For each element
      for (const ele in elements) {
        if (!elements.hasOwnProperty(ele)) continue;
        // Rebar weight and concrete are cumulated
        const eleInfo = dataByElement[story][ele];
        totalRebar += eleInfo.total_rebar_weight;
        totalConcrete += eleInfo.vol_concreto;
      }
    }

    // Cumulating values
    dataTotals["concrete_volume"].value += totalConcrete;
    dataTotals["reinforcement_total_weight"].value += totalRebar / 1000;

    // Getting detailed elements totals
    dataTotals["detailed_elements"].value +=
      reinforcementData[eletype][scenario]["detailed_elements"];
  });

  return (
    <>
      {/* Layout for reinforcement data   */}
      <div className="metrics-data-analysis">
        {/* Bar chart with by story distribution */}
        <div id="distribution-bar-chart">
          <StoryDistributionChart
            domElementID="distribution-bar-chart"
            chartType="material_totals"
            title="Distribution of Reinforcement by Story (tonf)"
          />
        </div>

        {/* Rendering totals boxes */}
        <div className="metrics-items">
          <div className="data-totals">
            {Object.keys(dataTotals).map((datatype, index) => (
              <div className="metrics-totals" key={index}>
                {/* Total data */}
                <div className="totals-read-data">
                  {Math.round(dataTotals[datatype].value * 10) / 10}
                </div>
                {/* Name of data */}
                <div className="totals-text">{dataTotals[datatype].text}</div>
              </div>
            ))}
          </div>

          {/* Concrete volume pie chart   */}
          <div id="pie-chart-volume">
            <PieChartByKey
              domElementID="pie-chart-volume"
              chartKey="concrete_volume"
            />
          </div>

          {/* Rebar weight by diameter pie chart   */}
          <div id="pie-chart-by-diameter">
            <PieChartByKey
              domElementID="pie-chart-by-diameter"
              chartKey="weight_by_diameter"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Materials;
