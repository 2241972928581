import React, { useEffect } from "react";
import { useSelector } from "react-redux";

// Third party libraries
import * as echarts from "echarts";

// importing Slice functions for handling state
import { getData } from "../../slicers/reinforcement-data/reinforcementSlice";

// Importing app's functionality
import {
  computeConstructionTime,
  computeElementMaterialsPrice,
} from "../utilities";

const StoryDistributionChart = ({ domElementID, chartType, title }) => {
  // Getting reinforcement data
  const reinforcementData = useSelector(getData);

  // Getting data that will be used on charts
  const dataForCharts = reinforcementData.dataToBeHandled;

  // Getting selected scenarios information
  const selectedScenarios = reinforcementData.selectedScenarios;
  // Getting prices and speed parameters
  const prices = reinforcementData.materialPrices;
  const speedParameters = reinforcementData.constructionSpeed;
  const priceByDiameter = reinforcementData.priceByDiameter;
  const materialPricesByDiameter = reinforcementData.materialPricesByDiameter;

  // Getting data as arrays for the chart of reinforcement weight distribution
  const prepareDataForMaterialsChart = (elementType, scenario) => {
    if (!scenario) {
      return {};
    }

    // As scenario by element can be modified total must be computed by element
    let dataByElement = dataForCharts[elementType]["by_element"];

    // Getting entries from data
    const entries = Object.entries(dataForCharts[elementType]["by_story"]);

    // Sorting by story heights
    entries.sort((a, b) => a[1].story_height - b[1].story_height);

    // Getting values for each element type
    const stories = entries.map((entry) => entry[0]);
    const data = [];

    // For each story
    stories.forEach((story) => {
      if (!dataByElement.hasOwnProperty(story)) return;
      let totalRebar = 0;
      const elements = dataByElement[story];
      // For each element
      for (const ele in elements) {
        if (!elements.hasOwnProperty(ele)) continue;
        // Rebar weight and concrete are cumulated
        const eleInfo = dataByElement[story][ele];
        totalRebar += eleInfo.total_rebar_weight;
      }
      // Adding data by story
      data.push(Math.round((totalRebar * 100) / 1000) / 100);
    });

    return { stories, data };
  };

  // Getting data as arrays for the chart of construction speed
  const prepareDataForSpeedChart = (elementType, scenario) => {
    if (!scenario) {
      return {};
    }

    // Getting entries from data
    const entries = Object.entries(dataForCharts[elementType]["by_story"]);

    // Sorting by story heights
    entries.sort((a, b) => a[1].story_height - b[1].story_height);

    // Getting values for each element type
    const stories = entries.map((entry) => entry[0]);

    const data = entries.map((entry) => {
      // The construction time depends on the complexity values of each element
      let constructionTime = 0;
      let dataByElement = dataForCharts[elementType]["by_element"];
      const elements = dataByElement[entry[0]];

      // For each element
      for (const ele in elements) {
        if (elements.hasOwnProperty(ele)) {
          // Construction time is summed
          constructionTime += computeConstructionTime(
            elements[ele],
            speedParameters
          );
        }
      }

      // Set construction time to rounded days
      return Math.round((constructionTime / 8) * 10) / 10;
    });

    return { stories, data };
  };

  // Getting data as arrays for the chart of materials cost chart
  const prepareDataForCostChart = (elementType, scenario) => {
    if (!scenario) {
      return {};
    }

    // As scenario by element can be modified total must be computed by element
    let dataByElement = dataForCharts[elementType]["by_element"];

    // Getting entries from data
    const entries = Object.entries(dataForCharts[elementType]["by_story"]);

    // Sorting by story heights
    entries.sort((a, b) => a[1].story_height - b[1].story_height);

    // Getting values for each element type
    const stories = entries.map((entry) => entry[0]);
    const data = [];

    // For each story
    stories.forEach((story) => {
      if (!dataByElement.hasOwnProperty(story)) return;
      let totalPrice = 0;
      const elements = dataByElement[story];
      // For each element
      for (const ele in elements) {
        if (!elements.hasOwnProperty(ele)) continue;
        // Rebar weight and concrete are cumulated
        const eleInfo = dataByElement[story][ele];
        totalPrice += computeElementMaterialsPrice(
          eleInfo,
          priceByDiameter,
          prices,
          materialPricesByDiameter
        );
      }
      // Adding data by story
      data.push(Math.round(totalPrice * 100) / 100);
    });

    return { stories, data };
  };

  const prepareDataFunctions = {
    material_totals: prepareDataForMaterialsChart,
    speed_construction: prepareDataForSpeedChart,
    cost_of_material: prepareDataForCostChart,
  };

  const dataFunction = prepareDataFunctions[chartType];

  // Processing object to ensure there are always values for each story
  const unifyStoriesAndDataForChart = (data) => {
    // Getting all the Stories
    const allStories = Array.from(
      // Spread the stories if they exist, otherwise use an empty array
      new Set([
        ...(data.beams?.stories || []),
        ...(data.joists?.stories || []),
        ...(data.columns?.stories || []),
        ...(data.walls?.stories || []),
      ])
    );

    // Initialize an empty object to hold the complete data
    const completeData = {};

    // Iterate over each element type in the data object
    for (const elementType in data) {
      // Get the data for the current element type
      const elementData = data[elementType];

      // Check if the elementData object is empty
      if (!Object.keys(elementData).length) {
        // If empty, assign allStories to stories
        elementData["stories"] = allStories;
        // Fill data with zeros
        elementData["data"] = Array(Object.keys(elementData.stories)).fill(0);
      }

      // Create a Map to associate each story with its corresponding data value
      const storyMap = new Map(
        elementData.stories.map((story, index) => [
          story,
          elementData.data[index],
        ])
      );

      // Add the completed stories and data to the completeData object
      completeData[elementType] = {
        // Use allStories as the stories array
        stories: allStories,
        // Map each story to its data value or 0 if not present
        data: allStories.map((story) => storyMap.get(story) || 0),
      };
    }

    // Calculate totals array
    const nStories = allStories.length;
    const totals = Array.from({ length: nStories }, (_, index) =>
      Object.values(completeData).reduce(
        (sum, { data }) => sum + data[index],
        0
      )
    );

    // Round to 2 digits
    const roundedTotals = totals.map((value) => parseFloat(value.toFixed(2)));
    // Store at data
    completeData["totals"] = roundedTotals;

    return completeData; // Return the complete data object
  };

  // Preparing final chart Data
  let chartData = {};

  // Getting data arrays for chart
  Object.entries(selectedScenarios).forEach((byElement, index) => {
    chartData[byElement[0]] = dataFunction(byElement[0], byElement[1]);
  });

  // Ensuring all values for the chart
  chartData = unifyStoriesAndDataForChart(chartData);

  // On mount
  useEffect(() => {
    // Getting DOM element
    const chartDom = document.getElementById(domElementID);

    // Initializing bar chart
    const chart = echarts.init(chartDom);

    const option = {
      title: {
        text: title,
        top: 5,
        textStyle: {
          fontSize: "0.75rem",
          fontWeight: "bold",
          color: "black",
          fontFamily:
            '"Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif',
        },
      },
      tooltip: {
        trigger: "axis",
        // Use axis to trigger tooltip
        axisPointer: {
          // When hovering item a shadow will appear
          type: "shadow",
        },
      },
      legend: {
        icon: "circle",
        bottom: 0,
        textStyle: {
          fontSize: "0.65rem",
          fontFamily:
            '"Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif',
        },
      },
      grid: {
        top: 40,
        left: "1%",
        containLabel: true,
      },
      xAxis: {
        type: "value",
        axisLabel: {
          color: "black",
          fontSize: "0.65rem",
          fontFamily:
            '"Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif',
        },
      },
      yAxis: {
        type: "category",
        data: chartData.beams.stories,
        axisLabel: {
          color: "black",
          fontSize: "0.65rem",
          fontFamily:
            '"Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif',
        },
      },
      series: [
        {
          name: "Beams",
          type: "bar",
          stack: "total",
          label: {
            show: false,
            fontSize: "0.65rem",
            fontFamily:
              '"Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif',
          },
          emphasis: {
            focus: "series",
          },
          data: chartData.beams.data,
          itemStyle: {
            color: "#85C1E9",
          },
        },
        {
          name: "Joists",
          type: "bar",
          stack: "total",
          label: {
            show: false,
            fontSize: "0.65rem",
            fontFamily:
              '"Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif',
          },
          emphasis: {
            focus: "series",
          },
          data: chartData.joists.data,
          itemStyle: {
            color: "#AF7AC5",
          },
        },
        {
          name: "Columns",
          type: "bar",
          stack: "total",
          label: {
            show: false,
            fontSize: "0.65rem",
            fontFamily:
              '"Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif',
          },
          emphasis: {
            focus: "series",
          },
          data: chartData.columns.data,
          itemStyle: {
            color: "#F5B041",
          },
        },
        {
          name: "Walls",
          type: "bar",
          stack: "total",
          label: {
            show: false,
            fontSize: "0.65rem",
            fontFamily:
              '"Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif',
          },
          emphasis: {
            focus: "series",
          },
          data: chartData.walls.data,
          itemStyle: {
            color: "#58D68D",
          },
        },
        {
          name: "Total",
          type: "bar",
          stack: "total",
          label: {
            show: Math.max(...chartData.totals) > 0,
            fontSize: "0.65rem",
            fontFamily:
              '"Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif',
          },
          emphasis: {
            focus: "series",
          },
          data: chartData.totals,
          itemStyle: {
            color: "#E74C3C",
          },
        },
      ],
    };

    // Setting chart configuration
    chart.setOption(option);

    // On dismount cleaning chart
    return () => {
      chart.dispose();
    };
  }, [reinforcementData]);

  return <div id={domElementID}></div>;
};

export default StoryDistributionChart;
