import { BrowserRouter, Routes, Route } from "react-router-dom";

// ProDes main components
import AppMainPage from "./main_page.js"
import AppProject from "./project_insight.js"

// ProDes App main component
const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* Main page (login) */}
        <Route path="/" element={<AppMainPage />} />
        {/* User project insight */}
        <Route path="/project" element={<AppProject />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;